import React from 'react'

export default function NotFound() {
    return (
        <div className="page">
            <div id="firstPage">

                <section className="wrapper">

                    <div className="container">

                        <div id="scene" className="scene">


                            <div className="circle" ></div>

                            <div className="one" >
                                <div className="content">
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                </div>
                            </div>

                            <div className="two">
                                <div className="content">
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                </div>
                            </div>

                            <div className="three">
                                <div className="content">
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                </div>
                            </div>

                            <p className="p404">404</p>
                            <p className="p404">404</p>

                        </div>

                        <div className="text">
                            <article>
                                <p>Oops! <br />Vous avez l'air perdu! <br />Vous souhaitez retourner à l'accueil?</p>
                                <a href="/">Retourner à l'accueil</a>
                            </article>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}
