import React from 'react'

// Images
import ComputerMeeting from '../images/OurServices/computer-meeting.png'
import MultiTaskingCharacter from '../images/OurServices/multitasking-character.png'
import CharacterPrintData from '../images/OurServices/character-print-data.png'
import ColorFullWaves from '../images/OurServices/colorful-waves.svg'

// Packages
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { scrollTriggerSection } from '../services/Helper/helpFunction'

export default function OurServices() {

    gsap.registerPlugin(ScrollTrigger);

    const sectionOne = document.getElementById('sectionOne');
    const sectionTwo = document.getElementById('sectionTwo');
    const sectionThree = document.getElementById('sectionThree');

    // ----- Animations ----- 
    if (sectionOne) {
        scrollTriggerSection(sectionOne, "x")
    }
    if (sectionTwo) {
        scrollTriggerSection(sectionTwo, "y")
    }
    if (sectionThree) {
        scrollTriggerSection(sectionThree, "x")
    }


    return (
        <div id="ourServices-component">

            <img className="wavesSvg" id="wavesSvg1" src={ColorFullWaves} alt="colorful waves blue" />
            <img className="wavesSvg" id="wavesSvg2" src={ColorFullWaves} alt="colorful waves blue" />

            <h2>Découvrez nos services</h2>

            <div id="componentGroup">
                <div className="oneService" id="sectionOne">
                    <div className="img-frame">
                        <img src={ComputerMeeting} alt="Personnages durant un meeting devant un ordinateur" />
                    </div>
                    <h3>Recherche unitaire</h3>
                    <hr></hr>
                    <div className="information-service">
                        Informations généales sur les entreprises:
                        <br /><br />
                        <ul>
                            <li>accès aux données de l’INSEE</li>
                            <li>accès aux données des Greffes</li>
                        </ul>

                        Dashboard complet sur l’entreprise et son dirigeant.
                        <br /><br />
                    </div>

                    <i>Recherche unitaire illimitée dès le pack STARTER</i>

                    <a className="btn-aliz btn-yellowFull" href="/#search-frame">Découvrir</a>
                </div>

                <div className="oneService" id="sectionTwo">
                    <div className="img-frame">
                        <img src={MultiTaskingCharacter} alt="Personnage travaillant sur plusieurs ordinateurs simultanément" />
                    </div>
                    <h3>Recherche multiple</h3>
                    <hr></hr>
                    <div className="information-service">
                        Solution sur mesure pour la vérification d’importants volumes de données:
                        <br /><br />
                        <ul>
                            <li>accès aux fonctionnalitées de la Recherche unitaire</li>
                            <li>accès aux données de Manageo</li>
                            <li>accès aux données de Société.com</li>
                        </ul>
                    </div>

    
                    <i>Recherche multiple illimitée dès le pack STARTER</i>

                    <a className="btn-aliz btn-yellowFull" href="/recherche-multiple">Découvrir</a>
                </div>

                <div className="oneService" id="sectionThree">
                    <div className="img-frame">
                        <img src={CharacterPrintData} alt="logo truc" />
                    </div>
                    <h3>Vérification et nettoyage des données légales d’entreprises</h3>
                    <hr></hr>
                    <div className="information-service">
                        Possibilité d’importer ses bases de données et d’en estimer la qualité:
                        <br /><br />
                        <ul>
                            <li>accès aux données du BODACC</li>
                            <li>accès au <b>ALIZ QUALITY SCORING</b></li>
                            <li>Bilans financiers téléchargeables</li>
                        </ul>
                    </div>

                    <i>Accès limité à 25 par mois avec le pack STARTER</i>


                    <a className="btn-aliz btn-yellowFull" href="/verification-donnees">Découvrir</a>
                </div>

                {/* <div className="oneService">
                    <div className="img-frame">
                        <img src={CharacterPrintData} alt="logo truc" />
                    </div>
                    <h3>Nettoyage des données légales d’entreprises</h3>
                    <hr></hr>
                    <p>
                        Possibilité d’importer ses bases de données et d’en estimer la qualité:
                        <br /><br />
                        <ul>
                            <li>accès aux données du BODACC</li>
                            <li>accès au <b>ALIZ QUALITY SCORING</b></li>
                            <li>Bilans financiers téléchargeables</li>
                        </ul>
                        <i>Recherches unitaires et vérification avec un accès de 1000 lignes par jour en free</i>
                    </p>
                    <button className="btn-aliz btn-yellowFull">Découvrir</button>
                </div> */}
            </div>
        </div>
    )
}
