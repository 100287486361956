import React, { useState } from 'react'

// ----- Components -----
import Bodacc from './Bodacc'

export default function BodaccAnnouncement({ response }) {

    const [expanded, setExpanded] = useState(false)
    const dataForDisplay = expanded ? response : response.slice(0, 1)


    return (
        <section className="row p-0 mt-5">

            <p className="subtitle">Annonces Bodacc</p>

            <hr />


            {dataForDisplay.map((data, index) => {
                return <Bodacc response={data} key={index} />
            })}
            
            <button type="button" className="my-5 btn-aliz btn-yellowFull col-auto m-auto" onClick={() => setExpanded(!expanded)}>
                {expanded ? 'Voir moins' : 'Consulter toute les annonces'}
            </button>

        </section>
    )
}
