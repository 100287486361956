import React from 'react'

// Packages
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Components
import Presentation from './Presentation'
import HowItWorks from './HowItWorks'
import Summary from '../Summary'

export default function VerifData({ responseToken, isSubscribed }) {
    return (
        <HelmetProvider>
            <Helmet prioritizeSeoTags>
                <title>Aliz - Vérification de données légales d'entreprise</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" value="Votre assistant virtuel de vérification et de nettoyage de données légales d'entreprise" />
                <link rel="canonical" href="https://legal-data.aliz.io" />
                <meta property="og:title" content="" />
            </Helmet>

            <div id="verificationData-page" className="page">

                <div id="firstPage">

                    <Presentation />

                    <div className="row justify-content-center">
                        <a className="btn-aliz btn-yellowFull col-10 col-sm-8 col-md-6 col-xl-4" href="/verification-donnees#howitWorks-component">Commencer la vérification des données</a>
                    </div>

                    {/* <div id="anchor-link">
                        <a href="/verification-donnees#upload-file">Déposer votre fichier</a>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                        </svg>
                    </div> */}
                </div>

                <HowItWorks responseToken={responseToken} isSubscribed={isSubscribed}/>
                <Summary />

            </div>
        </HelmetProvider>
    )
}
