import React, { useRef, useState } from 'react'

// Helper
import { copyToClipBoard } from '../../../services/Helper/helpFunction'

export default function LegalInformation({ response }) {
    const [copySuccess, setCopySuccess] = useState('');

    const na = "N/A";

    const rcs = response.informations_financieres.ville && response.informations_generales.siren ? "RCS " + response.informations_financieres.ville + " " + response.informations_generales.siren : na;

    const copySiren2Ref = useRef();
    const copySiretRef = useRef();
    const copyNafRef = useRef();
    const copyRcsRef = useRef();
    const copyTvaRef = useRef();


    return (
        <section className="row p-0">
            <p className="subtitle">Information légales</p>
            <hr />

            <div className="frameInformation col-12">
                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">SIREN</div>
                        <div className="information-copy">
                            <div>
                                <sup ref={copySiren2Ref}>{copySuccess}</sup>
                                {response.informations_generales.siren ? response.informations_generales.siren : na}
                                <svg
                                    onClick={() => copyToClipBoard(setCopySuccess, response.informations_generales.siren, copySiren2Ref)}
                                    title="Copier"
                                    className="copy-icon"
                                    viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5a8aff" ><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">SIRET</div>
                        <div className="information-copy">
                            <div>
                                <sup ref={copySiretRef}>{copySuccess}</sup>
                                {response.informations_generales.siret_siege && response.informations_generales.siret_siege}
                                <svg
                                    onClick={() => copyToClipBoard(setCopySuccess, response.informations_generales.siret_siege, copySiretRef)}
                                    title="Copier"
                                    className="copy-icon"
                                    viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5a8aff" ><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Code NAF</div>
                        <div className="information-copy">
                            <div>
                                <sup ref={copyNafRef}>{copySuccess}</sup>
                                {response.informations_generales.naf ? response.informations_generales.naf : na}
                                <svg
                                    onClick={() => copyToClipBoard(setCopySuccess, response.informations_generales.naf, copyNafRef)}
                                    title="Copier"
                                    className="copy-icon"
                                    viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5a8aff" ><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">RCS</div>
                        <div className="information-copy">
                            <div>
                                <sup ref={copyRcsRef}>{copySuccess}</sup>
                                {rcs}
                                <svg onClick={() => copyToClipBoard(setCopySuccess, rcs, copyRcsRef)}
                                    title="Copier"
                                    className="copy-icon"
                                    viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5a8aff" ><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">N° de TVA</div>
                        <div className="information-copy">
                            <div>
                                <sup ref={copyTvaRef}>{copySuccess}</sup>
                                {response.informations_generales.tva_intra ? response.informations_generales.tva_intra : na}
                                <svg
                                    onClick={() => copyToClipBoard(setCopySuccess, response.informations_generales.tva_intra, copyTvaRef)}
                                    title="Copier"
                                    className="copy-icon"
                                    viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5a8aff" ><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">Catégorie</div>
                        <div className="information-copy">
                            <div>
                                {response.informations_generales.categorie ? response.informations_generales.categorie : na}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Adresse</div>
                        <div className="information-copy">
                            <div>
                                {response.informations_generales.adresse_siege ? response.informations_generales.adresse_siege : na}
                            </div>
                        </div>
                    </div>

                    <div className="one-block-information">
                        <div className="information-name">Catégorie juridique</div>
                        <div className="information-copy">
                            <div>
                                {response.informations_generales.categorie_juridique ? response.informations_generales.categorie_juridique : na}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Code catégorie juridique</div>
                        <div className="information-copy">
                            <div>
                                {response.informations_generales.code_categorie_juridique && response.informations_generales.code_categorie_juridique}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">Date de création</div>
                        <div className="information-copy">
                            <div>
                                {response.informations_generales.date_creation ? response.informations_generales.date_creation : na}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Dernière actualisation</div>
                        <div className="information-copy">
                            <div>
                                {response.informations_generales.date_actualisation ? response.informations_generales.date_actualisation : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">Secteur</div>
                        <div className="information-copy">
                            <div>
                                {response.informations_generales.secteur && response.informations_generales.secteur}
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>

        </section>
    )
}
