import gsap, { Power2 } from 'gsap'

export const trimChar = function (string, charToRemove) {
    while (string.charAt(0) === charToRemove) {
        string = string.substring(1);
    }

    while (string.charAt(string.length - 1) === charToRemove) {
        string = string.substring(0, string.length - 1);
    }

    return string;
}

export const copyToClipBoard = async (setCopySuccess, copyMe, ref) => {
    try {
        await navigator.clipboard.writeText(copyMe);
        setCopySuccess('Copié!');
        gsap.fromTo(ref.current, { display: 'inline-block' }, { display: 'none', duration: 2 });

    } catch (err) {
        setCopySuccess('Erreur');
    }
};

export const scrollTriggerSection = (element, yOrx) => {
    if(yOrx === "x"){
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                start: 'top-=600',
                toggleActions: 'play none none reverse',
                end: () => `+=${element.clientHeight / 2}`,
                // markers: true
            }
        });
        tl.fromTo(element, 2, {
            x: -100,
            opacity: 0,
        },
            {
                x: 0,
                opacity: 1,
                ease: Power2
            }
        );
    }else{
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                start: 'top-=600',
                toggleActions: 'play none none reverse',
                end: () => `+=${element.clientHeight / 2}`,
            }
        });
        tl.fromTo(element, 2, {
            y: -100,
            opacity: 0,
        },
            {
                y: 0,
                opacity: 1,
                ease: Power2
            }
        );
    }
    
}