import React from 'react'


export default function PopupDownload({ onClose, email, percent }) {
    return (
        <div className="row justify-content-center popup-custom">

            <div className="header-popup">
                Votre base de données a bien été importée !
                <br />
                L'export peut prendre quelques minutes
                <br />

                {percent && percent !== 0 ? "Chargement de vos données: " + percent : ""}
                {percent !== "100%" && percent !== 0 && <div className="mini-loader"></div>}
            </div>

            <div className="body-popup">
                Nous vous enverrons vos données corrigées/scorées à l'adresse email suivante:
                <br />
                <b>{email && email}</b>
                <br />
                Ce n'est pas votre adresse email ? <a href={process.env.REACT_APP_PUBLIC_URL_ALIZ + "espace-client/profil"}>cliquez ici</a> pour la corriger
                <br />
                Attention, si le format ne correspond pas au modèle, il se peut que votre requête n'aboutisse pas
            </div>

            <div className="footer-popup row justify-content-center">
                <button className="btn-aliz btn-alizFull"
                    onClick={() => {
                        onClose();
                        window.location.href = process.env.REACT_APP_PUBLIC_URL_ALIZ
                    }}>
                    Je visite le site Aliz
                </button>

                <button className="btn-aliz btn-red"
                    onClick={() => {
                        onClose();
                    }}>
                    Fermer
                </button>
            </div>

        </div >
    )
}
