import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './style/App.scss'

// Axios 
import { checkToken } from './services/auth.js'
import { isVerifLegalSubscribed } from './services/user.js'

// Components Section
import Header from './component/Section/Header'
import BubbleUser from './component/Section/BubbleUser'
import Footer from './component/Section/Footer'

// Get location pathname
import LocationUrl from './component/Location'

// Pages
import Home from './component/Home/Home'
import UnitySearch from './component/UnitySearch/UnitySearch'
import MultipleSearch from './component/MultipleSearch/MultipleSearch'
import VerifData from './component/VerifData/VerifData'
import Prices from './component/Prices'
import NotFound from './component/NotFound'


export default function App() {

  const [location, setLocation] = useState('')
  const [token, setToken] = useState("")
  const [responseToken, setResponseToken] = useState([])
  const [isSubscribed, setIsSubscribed] = useState([])

  // check if user is already logged
  useEffect(() => {
    checkToken(setResponseToken)
    isVerifLegalSubscribed(setIsSubscribed)
  }, []);

  useEffect(() => {
    if (responseToken.initials) {
      setToken(true);
    } else if (responseToken === false) {
      setToken(false);
    }
  }, [responseToken])

  useEffect(() => {
    if (location.match(/espace-client/) && !location.match(/espace-client\/souscription-etape1/)) {
      if (responseToken === false) {
        return window.location.href = "https://aliz.io/login"
      }
    }
  }, [location, responseToken])

  return (
    <Router>
      <div className="App">
        {/* Get Url pathname location  */}
        <LocationUrl setLocation={setLocation} />

        <Header token={token} />
        {token && !location.match(/landing/) &&
          <BubbleUser token={responseToken} isSubscribed={isSubscribed} />
        }

        <Routes>
          {/* 404 Not Found */}
          <Route path="*" element={<NotFound />} />

          {/* Homepage */}
          <Route exact path="/" element={<Home token={token} />} />

          {/* Bot  */}
          <Route exact path="/recherche-unitaire/:siren" element={<UnitySearch token={token} isSubscribed={isSubscribed} />} />
          <Route exact path="/recherche-multiple" element={<MultipleSearch responseToken={responseToken} isSubscribed={isSubscribed} />} />
          <Route exact path="/verification-donnees" element={<VerifData responseToken={responseToken} isSubscribed={isSubscribed} />} />

          {/* Prices  */}
          <Route exact path="/tarifs" element={<Prices token={token} />} />

        </Routes>

        <Footer token={token} />

      </div >
    </Router>
  );
}