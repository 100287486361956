import React from 'react'

export default function SubscribeToEnjoy({ type, link }) {
  return (
    <div className="row justify-content-center" id="subscribeToEnjoy">

      <div className="col-md-10 col-xl-8">
        <p>
          Pour profiter de ce service, vous devez souscrire à une offre starter.
          <br /><br/>
          merci de votre compréhension.
        </p>

        {link && <a href={link} className="btn-aliz btn-yellowFull">Découvrir l'offre</a>}
      </div>

    </div>
  )
}
