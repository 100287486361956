import React, { useState, useEffect } from 'react'

// File Upload
import ModeleTemplateMultipleSearch from '../../upload/template_batch_search.xlsx'

// Packages
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

// Components
import PopupDownload from './../PopupDownload'
import Loader from './../Loader'

// Services
import { postBatchSearch } from '../../services/Search/multipleSearch'

export const Timeout = (time) => {
    let controller = new AbortController();
    setTimeout(() => controller.abort(), time * 1000);
    return controller;
};

export default function DownloadFile({ email }) {


    const [fileToDownload, setFileToDownload] = useState(null)
    const [errors, setErrors] = useState(false)
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState(false)
    const [percent, setPercent] = useState(0)


    const onChangeFile = (e) => {
        if (typeof e.target.files !== "undefined") {
            setFileToDownload(e.target.files[0])
        }
    }

    const onSearchEnterpriseByFile = () => {
        if (fileToDownload) {
            setLoading(true)
            const form = new FormData();
            form.append('mail', email);
            form.append('files[]', new File([fileToDownload], fileToDownload.name));
            // console.log(fileToDownload, form)
            postBatchSearch(form, setResponse, setErrors, setLoading, setPercent)
        } else {
            setErrors("Veuillez télécharger votre fichier")
        }

    }

    useEffect(() => {
        if (response) {

            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <PopupDownload onClose={onClose} email={email} percent={percent} />
                    );
                }
            })
        }
        // eslint-disable-next-line
    }, [response])


    return (
        <div className="row justify-content-center">
            <div id="uploadFile-component" className="col-lg-9 col-xl-7 search-frame">
                <h3>Déposer ses données</h3>
                <p className="explain">
                    1. Télécharger préalablement notre modèle afin d'organiser vos données de la même manière.

                </p>
                <a href={ModeleTemplateMultipleSearch} download className="btn-aliz btn-alizFull mb-5">
                    Télécharger le modèle
                </a>

                <p className="explain">
                    2. Importez votre fichier au format csv, xls ou xlsx.
                </p>

                <div className="row justify-content-center">
                    <label htmlFor="fileData" className="chooseFile col-11 col-md-10 col-lg-8 col-xl-6">
                        <input type="file"
                            id="fileData" name="fileData"
                            hidden
                            onChange={onChangeFile}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                        Choisir un fichier
                    </label>
                    <output id="output" className="mt-4">{fileToDownload && fileToDownload?.name}</output>
                    {errors && <span className="text-danger">{errors}</span>}

                </div>

                {loading && <Loader />}

                <p className="explain">3. Votre fichier vous sera envoyé sur votre adresse mail une fois le traitement des données terminés</p>


                <button className="btn-aliz btn-yellowFull" onClick={onSearchEnterpriseByFile}>Commencer la vérification</button>
            </div>
        </div>
    )
}
