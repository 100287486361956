import React, { useRef, useState, useEffect } from 'react'

//Packages
import gsap from "gsap"

// Helper
import { copyToClipBoard } from '../../services/Helper/helpFunction'

export default function OneResult({ data, sirenNumber }) {
    const [copySuccess, setCopySuccess] = useState('');
    const [siretNumber, setSiretNumber] = useState("")

    const na = "N/A";
    // const rcs = data.informations_financieres.ville && data.informations_generales.siren ? "RCS " + data.informations_financieres.ville + " " + data.informations_generales.siren : na;

    // ----- Ref Show more -----
    const showMoreRef = useRef();
    const showMoreArrowRef = useRef();

    // ----- Ref pour copier/coller -----
    const copySirenRef = useRef();
    const copySiretRef = useRef();
    const copyNafRef = useRef();
    const copyAddressRef = useRef();

    const showMore = () => {
        if (showMoreRef.current.style.display === 'none') {
            gsap.set(showMoreRef.current, { display: 'block', opacity: 1 });
            gsap.set(showMoreArrowRef.current, { transform: 'rotate(180deg)' });
        } else {
            gsap.set(showMoreRef.current, { display: 'none', opacity: 0 });
            gsap.set(showMoreArrowRef.current, { transform: 'rotate(0)' });
        }
    };

    useEffect(() => {
        if (data.informations_generales.siret_siege) {
            setSiretNumber(data.informations_generales.siret_siege)
        }
    }, [data])

    return (
        <div className="oneResult row">
            {data.query &&
                <div id="yourResearch">
                    <p>RECHERCHE : <i> {data.query}</i></p>
                </div>
            }

            <div className="col-12 col-sm-7 col-xl-3 socialReason">{data.informations_generales.denomination ? data.informations_generales.denomination : na}</div>
            <div className="only-xl col-xl-2">{data.informations_generales.siren ? data.informations_generales.siren : sirenNumber}</div>
            <div className="col-10 col-sm-4 col-xl-2 activity">{data.informations_generales.secteur && data.informations_generales.secteur}</div>
            <div className="only-xl col-xl-2">{data.informations_generales.date_creation && data.informations_generales.date_creation}</div>
            <div className="only-xl col-xl-2">{data.informations_generales.categorie && data.informations_generales.categorie}</div>
            <div className="col-1">
                <svg ref={showMoreArrowRef} onClick={showMore} xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
            </div>
            <div className="moreInformation" ref={showMoreRef}>
                <div className="row">
                    <div className="frameInformation col-11">
                        <div className="block-informations">
                            <div className="one-block-information">
                                <div className="information-name">SIREN</div>
                                <div className="information-copy">
                                    <p>
                                        <sup ref={copySirenRef}>{copySuccess}</sup>
                                        {data.informations_generales.siren ? data.informations_generales.siren : sirenNumber}
                                        <svg onClick={() => copyToClipBoard(setCopySuccess, data.informations_generales.siren, copySirenRef)} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="copy-icon"><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z" /></svg>
                                    </p>
                                </div>
                            </div>
                            <div className="one-block-information">
                                <div className="information-name">SIRET Siège</div>
                                <div className="information-copy">
                                    <p>
                                        <sup ref={copySiretRef}>{copySuccess}</sup>
                                        {siretNumber ? siretNumber : na}
                                        <svg onClick={() => copyToClipBoard(setCopySuccess, siretNumber, copySiretRef)} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="copy-icon"><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z" /></svg>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="block-informations mt-4">
                            <div className="one-block-information">
                                <div className="information-name">Code NAF</div>
                                <div className="information-copy">
                                    <p>
                                        <sup ref={copyNafRef}>{copySuccess}</sup>
                                        {data.informations_generales.naf ? data.informations_generales.naf : na}
                                        <svg onClick={() => copyToClipBoard(setCopySuccess, data.informations_generales.naf, copyNafRef)} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="copy-icon"><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z" /></svg>
                                    </p>
                                </div>
                            </div>
                            <div className="one-block-information">
                                <div className="information-name">Adresse</div>
                                <div className="information-copy">
                                    <p>
                                        <sup ref={copyAddressRef}>{copySuccess}</sup>
                                        {data.informations_generales.adresse_siege ? data.informations_generales.adresse_siege : na}
                                        <svg onClick={() => copyToClipBoard(setCopySuccess, data.informations_generales.adresse_siege, copyAddressRef)} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="copy-icon"><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z" /></svg>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="see-all-information" >
                    <a href={"/recherche-unitaire/" + siretNumber}>Voir toutes les informations sur l'entreprise</a>
                </div>
            </div>
        </div>
    )
}
