import React from 'react'


export default function DashboardDetails({ response }) {

    const na = "N/A";


    return (
        <section className="row pt-4">
            <div className="col-xl-8 ">
                <div className="frame">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-currency-euro" viewBox="0 0 16 16">
                        <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
                    </svg>
                    <b>Chiffre d'affaires</b>
                    <p>{response.informations_financieres.ca_2022 ? response.informations_financieres.ca_2022 : na}</p>
                </div>
                <div className="frame">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-calendar-event-fill" viewBox="0 0 16 16">
                        <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-3.5-7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                    </svg>
                    <b>Date de création</b>
                    <p>{response.informations_generales.date_creation ? response.informations_generales.date_creation : na}</p>
                </div>
                {/* <div className="frame">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                    <b>Dirigeant</b>
                    <p>{response.informations_generales.prenom_personne_physique ? response.informations_generales.prenom_personne_physique : na}</p>
                </div> */}

                <div className="frame">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-piggy-bank-fill" viewBox="0 0 16 16">
                        <path d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595Zm7.173 3.876a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199Zm-8.999-.65a.5.5 0 1 1-.276-.96A7.613 7.613 0 0 1 7.964 3.5c.763 0 1.497.11 2.18.315a.5.5 0 1 1-.287.958A6.602 6.602 0 0 0 7.964 4.5c-.64 0-1.255.09-1.826.254ZM5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                    </svg>
                    <b>Trésorerie</b>
                    <p>{response.informations_generales.resultat_2022 ? response.informations_generales.resultat_2022 + " €" : na}</p>
                </div>


                {/* <div className="frame">
                    <div className="needRegistered">
                        <p>Scoring Aliz</p>
                        <a className="btn-aliz btn-alizFull"
                            href={process.env.REACT_APP_PUBLIC_URL_ALIZ + "register"}
                            target="_blank" rel="noopener noreferrer">
                            M'inscrire
                        </a>
                    </div>
                    <img src={AlizLogo} alt="Aliz logo" />
                    <b>Scoring Aliz</b>
                    <p>B</p>
                </div> */}

                {/* <div className="frame">
                    <div className="needSubscribed">
                        <p>d.u.n.s number</p>
                        <a className="btn-aliz btn-yellowFull"
                            href={process.env.REACT_APP_PUBLIC_URL_ALIZ + "tarifs?assistant=verifications-données-légales&pack-annuel=true"}
                            target="_blank" rel="noopener noreferrer">
                            M'abonner
                        </a>
                    </div>

                    <img src={DunsLogo} alt="D.U.N.S logo" />
                    <b>d.u.n.s number</b>
                    <p>1485 8411</p>
                </div> */}
            </div>

            <div className="col-xl-4" id="dashboard-block">
                {/* <b>Afficher le Dashboard Aliz</b> */}
                {/* <div id="dashboard">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    Copier le Dashboard
                    <svg onClick={() => copyToClipBoard(setCopySuccess, "dashboard", copyDashboardRef)}
                        viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5a8aff" className="copy-icon"><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z" /></svg>
                    <sup ref={copyDashboardRef}>{copySuccess}</sup>
                    <div>
                        <img src={CharactersPresentsData} alt="Personnage qui analyse les données sur son ordinateur" className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-12" />
                    </div>
                </div> */}
                <div id="dashboard-soon">
                    <p>
                        Bientot disponible chez AlizVerif :
                        <br /><br />
                        un dashboard complet et téléchargeable.
                    </p>
                </div>
            </div>
        </section>
    )
}
