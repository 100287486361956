import React from 'react'

// Packages
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Component
import DownloadFile from './DownloadFile'
import SearchByInputs from './SearchByInputs'

// Images
import CharacterSearchData from '../../images/Characters/character-search-data.png'

export default function Search({ email }) {


    return (
        <div id="unitSearch-component" className="search-component">
            <div className="bubble-turquoise" id="bubble1"></div>
            <div className="bubble-turquoise" id="bubble2"></div>
            <div className="bubble-turquoise" id="bubble3"></div>
            <div className="bubble-turquoise" id="bubble4"></div>
            <div className="bubble-turquoise" id="bubble5"></div>

            <LazyLoadImage
                alt={"Personnages recherchant des données sur un dashboard"}
                src={CharacterSearchData} // use normal <img> attributes as props
                className="illustration"
                effect="blur" />

            <DownloadFile email={email} />

            <SearchByInputs email={email} />

        </div >
    )
}
