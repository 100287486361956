import React, { useState } from 'react'

// Packages 
import ReactPaginate from "react-paginate";

// Components
import Loader from '../Loader'
import OneResult from '../Home/OneResult'

export default function ResultByInputs({ response, errors, loading }) {
    // Pagination
    const [currentPage, setCurrentPage] = useState(0)

    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(response.length / PER_PAGE);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    return (
        <div id="searchResult-component">
            <h3>Liste des entreprises</h3>


            {response && response.length > 0 &&
                <div id="numberOfResults">
                    <b>{response.length}</b>
                    <p>Résultats pour votre recherche</p>
                </div>
            }
            <div id="allResults">
                <div id="head-table" className="row">
                    <div className="col-7 col-xl-3">Raison Sociale</div>
                    <div className="only-xl">Département</div>
                    <div className="col-4 col-xl-2">Secteur</div>
                    <div className="only-xl">Création</div>
                    <div className="only-xl">Catégorie</div>
                </div>

                {errors.length > 0 && <p className="no-result">Désolé, aucun résultat n'a été trouvé.</p>}

                {(() => {
                    if (errors.length <= 0 || errors === false) {
                        if (response.length === 0 && loading === false) {
                            return <p className="awaiting-result">Retrouvez tous vos résultats ici.</p>
                        } else if (response.length > 0 && loading === false) {
                            return response
                                .slice(offset, offset + PER_PAGE)
                                .map((data, index) => {
                                    return <OneResult data={data} key={index} sirenNumber={data.informations_generales.siren} />
                                })
                        } else {
                            return <Loader />;
                        }
                    }
                })()}
            </div>

            <div className="row justify-content-center">
                {(() => {
                    if (errors === false) {
                        if (response.length > 0 && loading === false) {
                            return <div className="col-xl-6">
                                <ReactPaginate
                                    previousLabel={"←"}
                                    nextLabel={"→"}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                />
                            </div>
                        }
                    }
                })()}
            </div>
        </div>
    )
}
