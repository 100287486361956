import React, { useState } from 'react'
// import { useLocation, useNavigate } from 'react-router-dom'

// Packages
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Select from 'react-select'
import ReactPaginate from "react-paginate";

// Services
// import { getUnitSearchByName, getUnitSearchBySiren, getUnitSearchBySiret } from '../../services/Search/search'


// Component
import DoSearchNow from '../DoSearch/DoSearchNow'
import InputSearch from '../DoSearch/InputSearch'
import OneResult from './OneResult'
import Loader from '../Loader'

// Images
import CharactersAnalysData from '../../images/Characters/characters-analys-data.png'


export default function UnitSearch({ token, pushSearch, setSearchEnterprise, searchEnterprise, response, errors, loading, sirenNumber, orderBy, setOrderBy }) {

    // ----- Pagination -----
    const [currentPage, setCurrentPage] = useState(0)

    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(response.length / PER_PAGE);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    // ----- Select orderBy Options -----
    const optionsOrderBy = [
        { value: "ASC", label: 'Alphabet, Croissant' },
        { value: "DESC", label: 'Alphabet, Décroissant' },
        { value: "DATE_CREATION_ASC", label: 'Date de création, Croissant' },
        { value: "DATE_CREATION_DESC", label: 'Date de création, Décroissant' }
    ];



    // console.log(response)

    return (
        <div id="unitSearch-component" className="search-component">
            <div className="bubble-turquoise" id="bubble1"></div>
            <div className="bubble-turquoise" id="bubble2"></div>
            <div className="bubble-turquoise" id="bubble3"></div>
            <div className="bubble-turquoise" id="bubble4"></div>
            <div className="bubble-turquoise" id="bubble5"></div>

            <LazyLoadImage
                alt={"Personnages analysant des données sur un bloc note"}
                src={CharactersAnalysData} // use normal <img> attributes as props
                className="illustration"
                effect="blur" />

            <div className="row justify-content-center">
                <div id="search-frame" className="col-lg-10 col-xl-8 search-frame">
                    <DoSearchNow />
                    <InputSearch placeholder={"Nom, SIRET OU SIREN"} setChange={setSearchEnterprise} value={searchEnterprise} id={"search"} />
                    <button onClick={pushSearch} className="btn-aliz btn-yellowFull">Envoyer</button>
                </div>
            </div>


            <div id="searchResult-component">
                <h3>Liste des entreprises</h3>

                <div id="yourResearch">
                    <p>RECHERCHE : <i> {searchEnterprise.replace(/-/g, " ")}</i></p>

                    <Select
                        defaultValue={orderBy}
                        onChange={(e) => setOrderBy(e.value)}
                        options={optionsOrderBy}
                        placeholder={"Trier par"}
                        className="custom-select"
                    />
                </div>

                {response &&
                    <div id="numberOfResults">
                        <b>{response.length}</b>
                        <p>Résultats pour votre recherche</p>
                    </div>
                }
                <div id="allResults">
                    <div id="head-table" className="row">
                        <div className="col-7 col-xl-3">Raison Sociale</div>
                        <div className="only-xl">Siren</div>
                        <div className="col-4 col-xl-2">Secteur</div>
                        <div className="only-xl">Création</div>
                        <div className="only-xl">Catégorie</div>
                    </div>

                    {errors && <p className="no-result">Désolé, aucun résultat n'a été trouvé.</p>}


                    {(() => {
                        if (!errors) {
                            if (response.length === 0 && !loading) {
                                return <p className="awaiting-result">Retrouvez tous vos résultats ici.</p>
                            } else if (response.length > 0 && !loading) {
                                return response
                                    .slice(offset, offset + PER_PAGE)
                                    .map((data, index) => {
                                        return <OneResult data={data} key={index} sirenNumber={sirenNumber} />
                                    })
                            } else if(loading) {
                                return <Loader />;
                            }
                        }
                    })()}
                </div>

                <div className="row justify-content-center">
                    {(() => {
                        if (!errors) {
                            if (response.length > 0 && !loading) {
                                return <div className="col-xl-6">
                                    <ReactPaginate
                                        previousLabel={"←"}
                                        nextLabel={"→"}
                                        pageCount={pageCount}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"pagination__link"}
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={"pagination__link--active"}
                                    />
                                </div>
                            }
                        }
                    })()}
                </div>
            </div>

        </div>
    )
}
