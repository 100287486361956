import React from 'react'

// Images
import CharacterPrintData from '../../images/Characters/character-print-data.png'


export default function Presentation() {

    return (
        <div id="first-block-presentation" className="row">
            {/* -----  Catch phrase & Cta ----- */}
            <div className="col-11 col-sm-10 col-md-8 col-lg-6 flex-direction-column">

                <h1>Vérification des données légales d'entreprise</h1>

                <p>
                    Importez votre base de données d'entreprises et vérifiez qu'elles
                    sont bien à jour. Obtenez un score de pertinence des informations avec le scoring Aliz et
                    remplacez les données érronées par celles officielles.
                    Recevez des alertes en cas d'actualisation.
                </p>
            </div>

            <img src={CharacterPrintData} alt="Personnage qui imprime les données sur son téléphone" className="col-10 col-sm-8 col-md-6 offset-lg-1 col-lg-5 col-xl-4" />
        </div>
    )
}
