import React from 'react'

// Images
import MultiTaskingData from '../../images/Characters/multitasking-character.png.png'

export default function Presentation() {

    return (
        <div id="first-block-presentation" className="row">
            {/* ----- Catch phrase & Cta ----- */}
            <div className="col-11 col-sm-10 col-md-8 col-lg-6 flex-direction-column">
                <h1>Découvrez la recherche multiple</h1>
                <p>
                    Aliz compile les données de l’INSEE, Greffe, Manageo, Societe.com et bien plus encore, 
                    pour vous fournir des informations fiables sur de multiples entreprises.
                    Faites des recherches groupées et exportez les résultats pour gagner du temps
                </p>
            </div>

            <img src={MultiTaskingData} alt="Personnage qui analyse les données sur plusieurs écrans simultanément" className="col-10 col-sm-8 col-md-6 offset-lg-1 col-lg-5 col-xl-4" />
        </div>
    )
}
