import axios from 'axios';
// import { fetchWithTimeout } from '../timeout'

const url = process.env.REACT_APP_VERIF_API_ENDPOINT;
const URI_TIMEOUT = "https://script.googleusercontent.com/a/macros/aliz.io/echo?user_content_key=vvfyDJqwfOx2hqUZcHbC3-7jyNvygEo513BZRdzLmFqraB-j0_HXBiOHi-u5UATfLAYB3hxgUTKkJppTo2_T005IwfcsSQrOm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_nRPgeZU6HP-ENtOcI7ePlLm3f0mqGBCRtOBGtObRBo17lY3XPx9SV1WzCGwJ7jX7V7MwN8O4kJpWg_AeE_vfBnG3_vOPyz1eVCXfX3zXiN6YOzsHW9lb0J_vaSn1jLtD&lib=MCVI9Pd8qu9M-OJTmB06YmuaMJ5Q-zlzu"

const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};


let myHeaders = new Headers();
myHeaders.append("Content-Type", 'application/json');
myHeaders.append("Access-Control-Allow-Origin", "*");
myHeaders.append("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,PATCH,OPTIONS");

export async function getMultipleSearchByInputs(setResponse, setErrors, setLoading, URI_PARAMS) {
    await axios({
        method: 'GET',
        url: url + URI_PARAMS,
        headers: headers,
    })
        .then((response) => {
            if (response.status === 200 && response.data.results) {
                setResponse(response.data.results);
                setLoading(false)
            } else {
                setErrors(true)
                setLoading(false)
            }
        }, (err) => {
            setErrors(true)
        });
}
export function postBatchSearch(form, setResponse, setErrors, setLoading, setPercent) {

    fetch(url + 'search/batch_search', {
        method: 'POST',
        body: form,
        // headers: myHeaders
    })
        .then((response) => {
            if (response) {
                console.log(response)
                if (response.status === 200 || response.status === 503) {
                    setResponse(true);
                    getPercentBatchSearch(setResponse, setErrors, setLoading, setPercent)
                } else {
                    setResponse(false);
                    setErrors("Votre fichier ne peut être pris en charge")
                }
                setLoading(false)
            } else {
                setResponse(false);
                setLoading(false)
                setErrors("Votre fichier ne peut être pris en charge")
            }
        }, (err) => {
            getPercentBatchSearch(setResponse, setErrors, setLoading, setPercent)
        });
}


export function getPercentBatchSearch(setResponse, setErrors, setLoading, setPercent) {
    fetch(URI_TIMEOUT,
        {
            headers: headers,
        })
        .then((response) => {
            console.log(response)
            if (response.data && response.data.data && response.data.data.statut) {
                if (response?.data?.data?.statut !== "100%") {
                    getPercentBatchSearch(setResponse, setErrors, setLoading, setPercent)
                }
                setPercent(response?.data?.data?.statut)
                setResponse(true);
            } else {
                setResponse(false);
                setErrors("Certaines données semblent erronées");
            }
            setLoading(false)

        }, (err) => {
            console.log(err)
            setResponse(false);
            setErrors("Le fichier n'a pas été pris en compte")
            setLoading(false)
        });
}


