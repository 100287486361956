import React from 'react'

export default function IsBloquedPeriod({ link }) {
  return (
    <div className="row justify-content-center" id="subscribeToEnjoy">
      <div className="col-md-10 col-xl-8">

        <p>
          Vous avez consommé tous les crédits de votre offre pour cette période.
          <br />
          Pour continuer à profiter de nos services, vous pouvez souscrire à une offre payante.
          <br /><br/>
          merci de votre compréhension.
        </p>

        {link && <a href={link} className="btn-aliz btn-yellowFull">Découvrir l'offre</a>}
        
      </div>
    </div>
  )
}
