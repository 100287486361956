import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import gsap from "gsap"
import Arrow from '../../images/Icon/Arrow - Down.svg'
import LogoBlue from "../../images/Logo/Logo Couleur_Sans texte.png"


export default function HeaderTablet({ token }) {
    const [nav, setNav] = useState(false);
    const [toggled, setToggled] = useState(false);

    const toggledNavRef = useRef(null);
    const navRef = useRef(null)

    const [disconnectedUser, setDisconnectedUser] = useState(false)

    const alizUrl = process.env.REACT_APP_PUBLIC_URL_ALIZ

    const toggledNavbar = (e) => {
        e.preventDefault();
        setNav(!nav ? true : false)
    }

    useEffect(() => {
        if (!nav) {
            gsap.to(toggledNavRef.current, { display: 'none', opacity: 0, duration: 0.8 });
            gsap.to(navRef.current, { boxShadow: 'unset', backgroundColor: "transparent", duration: 0.8 });
        } else {
            gsap.to(toggledNavRef.current, { display: 'block', opacity: 1, duration: 0.8 });
            gsap.to(navRef.current, { boxShadow: '0 0 10px 10px #5887FF1F', backgroundColor: "white", duration: 0.8 });
        }
    }, [nav])


    // Log off user 
    const disconnected = () => {
        if (localStorage.getItem('tokenAliz')) {
            setDisconnectedUser(true)
        }
    }

    useEffect(() => {
        if (disconnectedUser) {
            localStorage.removeItem('tokenAliz');
            window.location.href = "/";
        }
    }, [disconnectedUser]);

    // Show or hide dropdown menu ressources
    const toggledMenu = (e) => {
        const idToggled = e.currentTarget.id.substring(0, 8);
        const linkToggled = e.currentTarget.id;

        setToggled(toggled === false ? true : false)
        if (toggled === false) {
            gsap.fromTo('#' + idToggled + 'Dropdown', 1, { display: 'none', opacity: 0 }, { display: 'block', opacity: 1, });
            gsap.to('#' + linkToggled + ' img', 0, { transform: 'rotate(180deg)' });

        } else {
            gsap.to('#servicesDropdown, #ressourcesDropdown, #integratioDropdown', 0.5, { display: 'none', opacity: 0 });
            gsap.to('#' + linkToggled + ' img', 0, { transform: 'rotate(0deg)' });

        }
    };



    return (
        <header id="headerTablet">

            <nav className="navbar navbar-expand-xl" ref={navRef}>

                {/* Logo */}
                <Link className="navbar-brand" to="/"><img src={LogoBlue} alt="Aliz Logo" />
                    <div>
                        <p>Aliz</p>
                        <b>Verif</b>
                    </div>
                </Link>

                {/* Button toggled responsive */}
                <button onClick={toggledNavbar} id="btn-toggle">
                    <span>
                        <svg className="bi bi-filter-right" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z" />
                        </svg>
                    </span>
                </button>

                {/* Toggled navbar  */}
                <div className="collapse navbar-collapse" id="navbarNavDropdown" ref={toggledNavRef}>

                    {/* Nav left */}
                    <ul className="navbar-nav mr-auto">

                        <div className="dropdown-divider"></div>

                        {/* Onclick show/hide dropdown menu assistants*/}
                        <li className="nav-item d-flex" id="servicesToggled" onClick={toggledMenu}>
                            <p>Nos services</p>
                            <img src={Arrow} alt="arrow down" />
                        </li>

                        {/* Menu dropdown assistants */}
                        <li className="row" id="servicesDropdown" ref={toggledNavRef}>
                            <div className="col-12">

                                {/* <div className="service-block" >
                                    <Link to="/" className="col-10 col-sm-8 col-md-6">
                                        <div className="body-text">
                                            <p className="dropdown-text">Nettoyage et synchronisation de vos données</p>
                                            <p className="light-text">
                                                Nettoyage de vos bases de données et rectification des données érronés, provenant de sources officielles.
                                            </p>
                                        </div>
                                    </Link>
                                </div> */}

                                <div className="service-block" >
                                    <Link to="/recherche-multiple" className="col-10 col-sm-8 col-md-6">
                                        {/* <img className="logo-md" src={SearchClientLogo} alt="character with magnifying glass " /> */}
                                        <div className="body-text">
                                            <p className="dropdown-text">Recherche multiple</p>
                                            <p className="light-text">
                                                Faites plusieurs recherche sur les entreprises simultanément
                                                et exportez les résultats.
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="service-block" >
                                    <Link to="/verification-donnees" className="col-10 col-sm-8 col-md-6">
                                        {/* <img className="logo-md" src={BookLogo} alt="Green account book" /> */}
                                        <div className="body-text">
                                            <p className="dropdown-text">Vérification des données légales d'entreprise</p>
                                            <p className="light-text">
                                                Importez vos bases de données afin d'en estimer la qualité grâce au scoring Aliz.
                                            </p>
                                        </div>
                                    </Link>
                                </div>

                                <div className="service-block" >
                                    <Link to="/" className="col-10 col-sm-8 col-md-6">
                                        {/* <img className="logo-md" src={BookLogo} alt="Green account book" /> */}
                                        <div className="body-text">
                                            <p className="dropdown-text">Recherche unitaire</p>
                                            <p className="light-text">
                                                Recherches sur des entreprises, leurs données, leurs dirigeants et bien plus encore.
                                            </p>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </li>
                        {/* End dropdown assistants */}

                        <div className="dropdown-divider"></div>

                        <li className="nav-item item-black">
                            <Link to="/tarifs">Tarifs</Link>
                        </li>

                    </ul>
                    {/* End nav left */}

                    {(() => {
                        if (!token) {
                            return <ul className="navbar-nav nav2">

                                <div className="dropdown-divider"></div>

                                <li className="nav-item">
                                    <a className="col-10 col-sm-4 col-lg-3 btn-aliz btn-yellowFull" href={alizUrl + "login"}>Connexion</a>
                                </li>

                                <div className="dropdown-divider"></div>

                                <li className="nav-item">
                                    <a className="col-10 col-sm-4 col-lg-3 btn-aliz btn-alizFull" href={alizUrl + "register"}>S'inscrire</a>
                                </li>
                            </ul>
                        } else {
                            return <ul className="navbar-nav nav2">
                                <div className="dropdown-divider"></div>
                                <li className="nav-item" onClick={disconnected}> <button className=" btn-aliz btn-yellowFull">Déconnexion</button></li>
                            </ul>
                        }
                    })()}

                </div>
            </nav>
        </header>
    )
}