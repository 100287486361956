import React from 'react'

// ----- Components -----
import Leader from './Leader'

export default function Leaders({ dirigeants }) {

    return (
        <section className="row p-0 mt-5" id="leaders-component">

            <p className="subtitle">Dirigeants</p>

            <hr />
            
            {dirigeants.map((data, index) => {
                return <Leader dirigeant={data} key={index} />
            })}

        </section>
    )
}
