import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Images
import LogoBlue from "../../images/Logo/Logo Couleur_Sans texte.png"
import Arrow from '../../images/Icon/Arrow - Down.svg'

import TableMultipeData from '../../images/Icon/tableau-de-bord.png'
import SearchClient from '../../images/Icon/search-client.svg'
import TableData from '../../images/Icon/table-des-matieres.png'

// Packages 
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import gsap from "gsap"


export default function HeaderDesktop({ token }) {

    const [disconnectedUser, setDisconnectedUser] = useState(false)

    const [toggled, setToggled] = useState(false);
    const toggledRef = useRef(null);

    const alizUrl = process.env.REACT_APP_PUBLIC_URL_ALIZ


    // Log off user 
    const disconnected = () => {
        if (localStorage.getItem('tokenAliz')) {
            setDisconnectedUser(true)
        }
    }
    useEffect(() => {
        if (disconnectedUser) {
            localStorage.removeItem('tokenAliz');
            window.location.href = "/";
        }
    }, [disconnectedUser]);


    // Show or hide dropdown menu and change text colors
    const toggledMenu = (e) => {
        const idToggled = e.currentTarget.id.substring(0, 8);
        const linkToggled = e.currentTarget.id;

        setToggled(!toggled ? true : false)
        if (!toggled) {
            gsap.fromTo('#' + idToggled + 'Dropdown', { display: 'none', opacity: 0 }, { display: 'block', opacity: 1, duration: 1 });
            gsap.to('#' + linkToggled + ' img', { transform: 'rotate(180deg)', duration: 0 });
        } else {
            gsap.to('#servicesDropdown, #ressourcesDropdown', { display: 'none', opacity: 0, duration: 0.5 });
            gsap.to('#servicesToggled img, #ressourcesToggled img', { transform: 'rotate(0deg)', duration: 0 });
        }
    };



    return (
        <header id="headerDesktop">
            <nav className="navbar navbar-expand-xl">

                {/* Logo */}
                <Link className="navbar-brand" to="/">
                    <LazyLoadImage
                        alt={"Aliz Logo"}
                        src={LogoBlue}
                        effect="blur" />
                    <div><p>Aliz</p><b>Verif</b></div>
                </Link>

                {/* This block is responsive  */}
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    {/* Nav left */}
                    <ul className="navbar-nav mr-auto">

                        {/* Onclick show/hide dropdown menu assistants*/}
                        <li className="nav-item">
                            <Link to="/tarifs">Tarifs</Link>
                        </li>

                        {/* Onclick show/hide dropdown menu assistants*/}
                        <li className="nav-item d-flex" id="servicesToggled" onClick={toggledMenu}>
                            <p>Nos Services</p>
                            <img alt={"arrow down"} src={Arrow} />
                        </li>



                        {/* ________________________________Menu dropdown assistants_______________________________ */}
                        <div id="servicesDropdown" ref={toggledRef}>
                            <hr className="mt-3"></hr>

                            <div className="row">

                                {/* Assistants section */}
                                <div className="col-xl-5 mt-3">
                                    <p className="dropdown-title">NOS SERVICES</p>

                                    <div className="row">
                                        <div className="col-xl-6 d-flex flex-direction-column">
                                            <Link to="/" className="d-flex mt-4 mb-2" >
                                                <LazyLoadImage
                                                    alt={"Fiche d'information"}
                                                    src={TableData}
                                                    className="logo"
                                                    effect="blur" />
                                                <div className="body-text">
                                                    <p className="dropdown-text">Recherche unitaire</p>
                                                    <p className="light-text">
                                                        Recherches sur des entreprises, leurs données, leurs dirigeants et bien plus encore.
                                                    </p>
                                                </div>
                                            </Link>

                                            <Link to="/verification-donnees" className="d-flex" >
                                                <LazyLoadImage
                                                    alt={"Homme avec une loupe "}
                                                    src={SearchClient}
                                                    className="logo"
                                                    effect="blur" />
                                                <div className="body-text">
                                                    <p className="dropdown-text">Vérification des données légales d'entreprise</p>
                                                    <p className="light-text">
                                                        Importez vos bases de données afin d'en estimer la qualité grâce au scoring Aliz.
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col-xl-6 d-flex flex-direction-column">
                                            <Link to="/recherche-multiple" className="d-flex mt-4 mb-2" >
                                                <LazyLoadImage
                                                    alt={"Tableaux de données"}
                                                    src={TableMultipeData}
                                                    className="logo"
                                                    effect="blur" />
                                                <div className="body-text">
                                                    <p className="dropdown-text">Recherche multiple</p>
                                                    <p className="light-text">
                                                        Faites plusieurs recherche sur les entreprises simultanément
                                                        et exportez les résultats.
                                                    </p>
                                                </div>
                                            </Link>
                                            {/* <Link to="/" className="d-flex">
                                               
                                                <div className="body-text ml-3 mt-3">
                                                    <p className="dropdown-text">Nettoyage et synchronisation de vos données</p>
                                                    <p className="light-text">
                                                        Nettoyage de vos bases de données et rectification des données érronés, provenant de sources officielles.
                                                    </p>
                                                </div>
                                            </Link> */}
                                        </div>


                                    </div>

                                </div>
                                {/* End assistants section */}

                                <hr className="hr-vertical"></hr>
                                <div className="col-xl-2 mt-5">

                                </div>
                            </div>
                        </div>


                    </ul>



                    {/* Nav right */}
                    {(() => {
                        if (token) {
                            return <ul className="navbar-nav nav2">
                                <li className="nav-item " onClick={disconnected}><button className="btn-aliz btn-alizFull">Déconnexion</button></li>
                            </ul>
                        } else {
                            return <ul className="navbar-nav nav2">
                                <li className="nav-item">
                                    <a href={alizUrl + "login"}>Connexion</a>
                                </li>

                                <li className="nav-item">
                                    <a href={alizUrl + "register"} className="btn-aliz btn-alizFull">S'inscrire</a>
                                </li>
                            </ul>
                        }
                    })()}
                    {/* End nav right */}

                </div>

            </nav>
        </header>
    )
}