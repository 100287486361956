import axios from 'axios';

const url = process.env.REACT_APP_API_ALIZ_ENDPOINT;

const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz')
}

// ----- Check notification number for bubble in header ----- 
export async function numberNotReadedNotification(setNumberNotReadedNotification) {
    await axios({
        url: url + 'auth/number_not_readed_notification',
        headers: headersAuth,
    })
        .then((response) => {
            if (response.data) {
                setNumberNotReadedNotification(response.data.numberNotReadedNotification)
            }
        }, (err) => {
            setNumberNotReadedNotification(false)
        });
}

// ----- Check if user had subscription ----- 
export async function isVerifLegalSubscribed(setResponse) {
    await axios({
        url: url + 'auth/isVerifLegalSubscribed',
        headers: headersAuth,
    })
        .then((response) => {
            if (response.data.response) {
                setResponse(response.data.response)
            }
        }, (err) => {
            setResponse(false)
        });
}


