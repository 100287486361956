import React from 'react'

import InputSearch from '../DoSearch/InputSearch'
import CharacterData from '../../images/Characters/character-view-data.png'

export default function Presentation({ pushSearch, setSearchEnterprise, searchEnterprise }) {


    return (
        <div id="first-block-presentation" className="row">
            {/* ----- Catch phrase & Cta ----- */}
            <div id="homepage-presentation" className="col-11 col-sm-10 col-md-8 col-lg-7 flex-direction-column">
                <h1>
                    Aliz, l'assistant intelligent qui vérifie les données d'entreprises
                </h1>
                <p>
                    Vous pouvez ici réaliser des recherches sur des entreprises,
                    leurs données et leurs dirigeants et bien plus encore
                </p>

                <InputSearch placeholder="Nom ou dirigeant, SIRET ou SIREN" setChange={setSearchEnterprise} value={searchEnterprise} id="searchEnterprise" />
                <button className="btn-aliz btn-yellowFull" onClick={pushSearch}>Rechercher</button>
            </div>

            <img src={CharacterData} alt="Personnage qui analyse les données sur son ordinateur" className="col-10 col-sm-8 col-md-6 offset-lg-1 col-lg-5 col-xl-4" />

        </div>
    )
}
