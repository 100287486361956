import React from 'react'

export default function DoSearchNow() {
    return (
        <div id="search-now">
            <h4>Faire votre recherche dès maintenant</h4>
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M11.561 1.061l-5.25 5.25-5.25-5.25" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
            </svg>
        </div>
    )
}
