import React, { useState, useEffect, useRef } from 'react'
// import { isCalendarSubscribed } from './../../services/User/calendar.js'
import { numberNotReadedNotification } from './../../services/user.js'

import Arrow from "../../images/Icon/Arrow - Down.svg"
import gsap from 'gsap'

export default function BubbleUser({ token, isSubscribed }) {
    const [toggle, setToggle] = useState(false)
    // const [isCalendar, setIsCalendar] = useState(false)
    const [responseNumberNotReadedNotification, setResponseNumberNotReadedNotification] = useState(0)


    const dropdown = useRef();
    const ul = useRef();

    useEffect(() => {
        const tl = gsap.timeline();
        if (toggle) {
            tl.to(dropdown.current, 0, {
                display: "block",
                height: "auto",
                ease: "power3.inOut"
            });
        } else {
            tl.to(dropdown.current, 0, {
                display: "none",
                height: 0,
                ease: "power3.inOut",
            });
        }
    }, [toggle]);

    useEffect(() => {
        // isCalendarSubscribed(setIsCalendar)
        numberNotReadedNotification(setResponseNumberNotReadedNotification)
    }, [])

    const alizUrl = process.env.REACT_APP_PUBLIC_URL_ALIZ

    return (
        <div id="bubbleUser-component">
            <div className="cicle-user d-flex" onClick={() => setToggle(toggle ? false : true)}>
                {responseNumberNotReadedNotification > 0 &&
                    <div id="circle-notif" className="responseNumberNotReadedNotification"><p>{responseNumberNotReadedNotification}</p></div>
                }
                <div className="circle">{token && token.initials}</div>
                <img src={Arrow} alt="arrow down" />
            </div>
            <div className="dropdown" ref={dropdown}>
                <ul ref={ul}>
                    <li><a href={alizUrl + "espace-client/accueil"}>Mes souscriptions</a></li>
                    <li><a href={alizUrl + "espace-client/profil"}>Informations personnelles</a></li>
                    <li><a href={alizUrl + "espace-client/mon-profil-professionnel"}>Informations professionnelles</a></li>
                    <li><a href={alizUrl + "espace-client/mes_factures"}>Mes factures</a></li>
                    <li><a href={alizUrl + "espace-client/mes_moyens_de_paiement"}>Mes moyens de paiments</a></li>
                    <li className="d-flex"><a href={alizUrl + "espace-client/mes_notifications"}>Mes notifications</a>
                        {responseNumberNotReadedNotification > 0 &&
                            <div className="responseNumberNotReadedNotification"><p>{responseNumberNotReadedNotification}</p></div>
                        }
                    </li>
                    <li id="isSubscribed">
                        {isSubscribed.isSubscribed === false ?
                            <a href={alizUrl + "tarifs?assistant=verifications-données-légales&pack-annuel=true"} className="btn-aliz btn-yellowFull">Découvrir AlizVerif</a> :
                            <a href={alizUrl + "espace-client/accueil"} className="btn-aliz btn-alizFull">Souscription {isSubscribed.type}</a>}
                    </li>

                    {/* {isCalendar &&
                        <button className="calendarBtn my-4" onClick={() => window.open(
                            "https://calendar.aliz.io/user/aliz/connect?token=" + localStorage.getItem('tokenAliz'),
                            '_blank' // <- This is what makes it open in a new window.
                        )}>Accéder à AlizCalendar</button>
                    } */}
                </ul>
            </div>
        </div>
    )
}
