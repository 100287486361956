import React from 'react'

// Images
import WomanProfessional from '../../../images/woman-professional.jpg'

export default function TestVerifData() {
  return (
    <section id="discoverVerifData" className="row justify-content-center">
      
      <div className="col-xl-4">
        <img src={WomanProfessional} alt="Femme qui travail à son bureau" />
      </div>
      <div id="discover-text" className="col-xl-8">
        <p>
          Vous avez une base de données entreprise et vous voulez vérifier la véracité de celles-ci?
          <br />
          Découvrez notre outil de vérification et nettoyage des bases de données.
          Importez votre base de données d'entreprises et vérifiez qu'elles sont bien à jour.
          <br />
          Obtenez un score de pertinence des informations, remplacez les données erronnées par celles
          officielles et recevez des alertes en cas d'actualisation.
        </p>
        <div id="logos">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z" />
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path d="M21.4231,13.88785,15.33356,3.33792H8.66663l6.09,10.54993ZM8.08917,4.33835,2,14.88736l3.33356,5.77472,6.08911-10.54926Zm1.73273,10.549L6.48877,20.66208H18.66663L22,14.88736Z" /></svg>

          <svg viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM128 384c-17.7 0-32-14.3-32-32c0-17.7 14.3-32 32-32s32 14.3 32 32C160 369.7 145.7 384 128 384zM128 288C110.3 288 96 273.7 96 256c0-17.7 14.3-32 32-32s32 14.3 32 32C160 273.7 145.7 288 128 288zM128 192C110.3 192 96 177.7 96 160c0-17.7 14.3-32 32-32s32 14.3 32 32C160 177.7 145.7 192 128 192zM480 352c0 13.25-10.75 24-24 24H215.1C202.7 376 192 365.3 192 352s10.75-24 23.1-24H456C469.3 328 480 338.7 480 352zM480 255.1C480 269.3 469.3 280 456 280H215.1C202.7 280 192 269.3 192 256c0-13.25 10.75-24 23.1-24H456C469.3 232 480 242.7 480 255.1zM480 159.1C480 173.3 469.3 184 456 184H215.1C202.7 184 192 173.3 192 160C192 146.7 202.7 136 215.1 136H456C469.3 136 480 146.7 480 159.1z" /></svg>
        </div>
      </div>
      <a className="btn-aliz btn-yellowFull mt-5" href="/">Tester la vérification de données d'entreprise</a>
    </section >
  )
}
