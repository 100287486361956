import React, { useState, useEffect } from 'react'

// File Upload
import ModeleData from '../../upload/template_verif_donnees.xlsx'

// Packages
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select'

// Components
import PopupDownload from './../PopupDownload'
import Loader from './../Loader'


export const Timeout = (time) => {
    let controller = new AbortController();
    setTimeout(() => controller.abort(), time * 1000);
    return controller;
};

const URI_2 = "https://script.googleusercontent.com/a/macros/aliz.io/echo?user_content_key=lSLRHOYsvF85HTjO-cE-3dAkXKCZDwg9_ihh7oKGbaj8cOHWA2onkFnq8dcoq-7MdaV0bOHlN0SMIbcK8I44pWpYdx2AWNoFm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_nRPgeZU6HP-ENtOcI7ePlLm3f0mqGBCRtOBGtObRBo17lY3XPx9SV1WzCGwJ7jX7V7MwN8O4kJpWg_AeE_vfBnG3_vOPyz1eVCXfX3zXiN6YOzsHW9lb0J_vaSn1jLtD&lib=MCVI9Pd8qu9M-OJTmB06YmuaMJ5Q-zlzu"

export default function DownloadFile({ email, isSubscribed }) {

    const [fileToDownload, setFileToDownload] = useState(null)
    const [errors, setErrors] = useState([])
    const [response, setResponse] = useState([])
    const [loading, setLoading] = useState(false)

    const onChangeFile = (e) => {
        if (typeof e.target.files !== "undefined") {
            setFileToDownload(e.target.files[0])
        }
    }


    // ----- Select ----- 
    const [option, setOption] = useState(null)

    // ----- Select Options ----- 
    const optionsChoice = [
        { value: "VERIF", label: 'Vérifier ma base de données' },
        { value: "CEAN", label: 'Nettoyer ma base de données' },
    ];


    // -----  Axios service here ----- 
    const headersAuth = new Headers({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': isSubscribed.token
    })

    const url = process.env.REACT_APP_VERIF_API_ENDPOINT;

    const onVerifDataByFile = () => {
        setResponse([])
        setErrors([])

        if (option === null && !optionsChoice.includes(option)) {
            setErrors("Veuillez choisir le type d'exportation souhaité")
        } else {
            if (fileToDownload) {
                switch (option) {
                    case "VERIF":
                        setLoading(true)
                        const form = new FormData();
                        form.append('mail', email);
                        form.append('files[]', fileToDownload, fileToDownload.name);


                        fetch(url + 'score/batch_scoring', {
                            method: 'POST',
                            body: form,
                            headers: headersAuth,
                            // signal: Timeout(10).signal
                        })
                            .then(function (response) {
                                setLoading(false)
                                if (response.status !== 200) {
                                    setErrors("Votre fichier ne peut être pris en charge")
                                } else {
                                    setResponse(response);
                                }
                            }, (err) => {
                                setLoading(false)
                                setErrors("Votre fichier ne peut être pris en charge")
                            });
                        break;
                    case "CEAN":
                        setLoading(true)
                        const forms = new FormData();
                        forms.append('mail', email);
                        forms.append('files[]', fileToDownload, fileToDownload.name);

                        fetch(url + 'clean/batch_cleaning', {
                            method: 'POST',
                            body: forms,
                            headers: headersAuth,
                        })
                            .then(function (response) {
                                setLoading(false)

                                if (response.status !== 200) {
                                    setErrors("Votre fichier ne peut être pris en charge")
                                } else {
                                    setResponse(response);
                                }
                            }, (err) => {
                                setLoading(false)
                                setErrors("Votre fichier ne peut être pris en charge")
                            });

                        break;
                    default:
                        break;
                }
            } else {
                setErrors("Veuillez télécharger votre fichier")
            }
        }
    }
    // -----  End Axios service ----- 

    useEffect(() => {
        if (response.status !== 200 && !errors && response.length > 0) {
            setErrors("Veuillez choisir un fichier conforme")
        } else if (response.status === 200) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <PopupDownload onClose={onClose} email={email} />
                    );
                }
            })
        }
        // eslint-disable-next-line
    }, [response])

    return (
        <div className="row justify-content-center">
            <div id="uploadFile-component" className="col-lg-9 col-xl-7 search-frame">

                <h3>Déposer ses données</h3>

                <p className="explain">1. Télécharger préalablement notre modèle afin d'organiser vos données de la même manière.</p>

                <a href={ModeleData} download className="btn-aliz btn-alizFull">Télécharger le modèle</a>


                <p className="explain">2. Importez votre fichier au format csv, xls ou xlsx.</p>



                <div className="row justify-content-center" >

                    <label htmlFor="fileData" className="chooseFile col-11 col-md-10 col-lg-8 col-xl-6">
                        <input type="file"
                            id="fileData" name="fileData"
                            hidden
                            onChange={onChangeFile}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                        Choisir un fichier
                    </label>

                    <output id="output" className="mt-4">{fileToDownload && fileToDownload?.name}</output>
                </div>

                {loading && <Loader />}

                <p className="explain">3. Précisez l'exportation souhaitée</p>

                <Select
                    defaultValue={option}
                    onChange={(e) => setOption(e.value)}
                    options={optionsChoice}
                    placeholder={"Je souhaite"}
                    className="custom-select my-4"
                />

                {errors && <span className="text-danger">{errors}</span>}

                <button className="btn-aliz btn-yellowFull" onClick={onVerifDataByFile}>Commencer la vérification</button>
            </div>
        </div>
    )
}
