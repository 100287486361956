import React from 'react'

// Images
import BroadcastIcon from "../images/Icon/broadcast-icon.png";
import ClipBoardIcon from "../images/Icon/clipboard-icon.png";
import ClockHistoryIcon from "../images/Icon/clock-history-icon.png";
import DiagramIcon from "../images/Icon/diagram-icon.png";

export default function Summary() {
    return (
        <div id="summary-component">

            <h2>Les fonctionnalités</h2>

            <div id="componentGroup">

                <div className="oneSummary">
                    <img src={DiagramIcon} alt="Diagramme circulaire" />
                    <p className="description-title">Connexions aux bases légales Françaises, Européennes et étrangères</p>
                </div>

                <div className="oneSummary">
                    <img src={ClockHistoryIcon} alt="Horloge" />
                    <p className="description-title">Intégration directe à votre ERP, CRM ou base de données</p>
                </div>

                <div className="oneSummary">
                    <img src={BroadcastIcon} alt="Groupe de personne" />

                    <p className="description-title">Vérification et enrichessement de données automatique</p>
                </div>

                <div className="oneSummary">
                    <img src={ClipBoardIcon} alt="Alarme - notification" />
                    <p className="description-title">Analyse de la qualité des données avec le "ALIZ QUALITY SCORING"</p>
                </div>

                {/* <div className="summary-header offset-1 col-10 col-md-10 offset-xl-0 col-xl-4">
                    <h2>Aliz votre assistant au quotidien</h2>
                    <p className="summary-description">Aliz, c’est plus qu’un assistant, c’est une nouvelle façon de travailler. Un moyen simple pour vous libérer des tâches sans valeur ajoutée.</p>
                    <a href={process.env.REACT_APP_LINK_CALENDAR_FOOTER_DEMO} target="_blank" rel="noopener noreferrer" className="summary-contact">Contactez-nous</a>
                </div> */}
            </div>
        </div>
    )
}
