import React, { useEffect, useState, useRef, useCallback } from 'react'

// Components
import DiscoverVerifData from './DetailsByType/DiscoverVerifData'
import DashboardDetails from './DetailsByType/DashboardDetails'
import LegalInformation from './DetailsByType/LegalInformations'
import FinancialInformation from './DetailsByType/FinancialInformations'
import BodaccAnnouncement from './DetailsByType/Bodacc/BodaccAnnouncements'
import Leaders from './DetailsByType/Leaders/Leaders'

// Images 
import CharactersPresentsData from '../../images/Characters/character-presents-data .png'
import PinMap from '../../images/Icon/pin.png'

// Packages 
import gsap from "gsap"
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

// Helper
import { copyToClipBoard } from '../../services/Helper/helpFunction'


export default function AllInformations({ response, token, isSubscribed }) {
    const [copySuccess, setCopySuccess] = useState('');
    const [about, setAbout] = useState("")

    const na = "N/A";
    // ref pour copier/coller
    const copySirenRef = useRef();

    useEffect(() => {
        // about
        if (response.informations_generales.denomination && response.informations_generales.categorie && response.informations_financieres.forme_juridique && response.informations_financieres.ville && response.informations_generales.date_creation && response.informations_generales.secteur && response.informations_financieres.ca_2022) {
            setAbout(response.informations_generales.denomination + " est une " + response.informations_generales.categorie + " spécialisée dans le " +
                response.informations_financieres.forme_juridique + ", et situé à  response.informations_financieres.ville. \n Le groupe a été créee le " +
                response.informations_generales.date_creation + ", et opére dans le secteur de " +
                response.informations_generales.secteur + ". \nCette année, le CA de l'entreprise s'élève à "
                + response.informations_financieres.ca_2022 + ".");
        }

        // eslint-disable-next-line
    }, [response])

    useEffect(() => {
        if (token) {
            gsap.set('.needRegistered', { display: 'none' });
        }
        if (isSubscribed) {
            gsap.set('.needSubscribed', { display: 'none' });
        }
    }, [token, isSubscribed])

    const containerStyle = {
        width: '400px',
        height: '400px'
    };

    const center = {
        lat: parseInt(response.informations_generales.latitude_siege),
        lng: parseInt(response.informations_generales.longitude_siege)
    };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD193cSgF1aQVOG1pfrgdmG9MQVW5DJb6Y"
    })

    // eslint-disable-next-line
    const [map, setMap] = useState(null)

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
        // eslint-disable-next-line
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    return (
        <div>

            <div id="header-result" className="row max-md-flex-direction-column-reverse">
                <div className="col-12 col-lg-6 flex-direction-column">
                    <h1>Résultat de votre recherche pour {response.informations_generales.denomination ? <b>{response.informations_generales.denomination}</b> : na}</h1>
                    <a className="btn-aliz btn-alizFull" href="/#search-frame">Effectuer une autre recherche</a>
                </div>

                <img src={CharactersPresentsData} alt="Personnage qui analyse les données sur son ordinateur" className="col-10 col-sm-8 offset-md-4 col-md-4 offset-lg-1 col-lg-5 col-xl-4" />
            </div>


            <div id="body-result">

                <p className="companyName">{response.informations_generales.denomination && response.informations_generales.denomination}</p>

                <div className="row">

                    <div className="col-sm-6 col-xl-3 presentation-line">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#FFCF59" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p>{response.informations_financieres.ville ? response.informations_financieres.ville : na} {response.informations_financieres.code_postal && '(' + response.informations_financieres.code_postal + ')'}</p>
                    </div>

                    <div className="col-sm-6 col-xl-3 presentation-line">
                        SIREN: {response.informations_generales.siren && response.informations_generales.siren}
                        <svg
                            onClick={() => copyToClipBoard(setCopySuccess, response.informations_generales.siren, copySirenRef)}
                            title="Copier"
                            className="copy-icon"
                            viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5a8aff" ><path d="M384 96L384 0h-112c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48H464c26.51 0 48-21.49 48-48V128h-95.1C398.4 128 384 113.6 384 96zM416 0v96h96L416 0zM192 352V128h-144c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h192c26.51 0 48-21.49 48-48L288 416h-32C220.7 416 192 387.3 192 352z" />
                        </svg>
                        <sup ref={copySirenRef}>{copySuccess}</sup>
                    </div>

                    <div className="col-xl-6 presentation-line">
                        <p>{response.informations_generales.secteur ? response.informations_generales.secteur : na}</p>
                    </div>
                    <hr />
                </div>


                <DashboardDetails response={response} />


                <div className="row">
                    <p className="subtitle">À propos de {response.informations_generales.denomination && response.informations_generales.denomination}</p>
                    <hr />
                    <p className="about-company-text">
                        {about ? about : na}
                    </p>
                </div>

                <LegalInformation response={response} siren={response.informations_generales.siren} />

                {response.informations_financieres !== "No informations found about this company" &&
                    <FinancialInformation informations_financieres={response.informations_financieres} />
                }

                {response.informations_generales.dirigeants &&
                    <Leaders dirigeants={response.informations_generales.dirigeants} />
                }

                <BodaccAnnouncement response={response.annonces_bodacc} />


                {isLoaded &&
                    <div className="row justify-content-center mt-5">
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={10}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        >
                            <Marker
                                position={center}
                                icon={PinMap} />
                            <></>
                        </GoogleMap>
                    </div>
                }

                <DiscoverVerifData />



                {/* ----- DO NOT DELETE Component with the design in case of downloadable documents ----- */}
                {/* <div className="row mt-5">
                    <p className="subtitle">Télécharger des documents</p>
                    <hr />
                    <div className="allDownloads">
                        <a href={CharactersPresentsData} download>
                            <b className="mr-5">Status Société</b>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                        </a>

                        <a href={CharactersPresentsData} download>
                            <b className="mr-5">Annonces BODACC</b>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                        </a>

                        <a href={CharactersPresentsData} download>
                            <b className="mr-5">Bilan financier</b>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                        </a>

                        <a href={CharactersPresentsData} download>
                            <b className="mr-5">Procédures collectives</b>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                        </a>
                    </div>
                </div> */}



            </div >
        </div >
    )
}
