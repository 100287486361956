import React from 'react'

export default function FinancialInformations({ informations_financieres }) {
    const na = "N/A";
    const euros = " €";

    return (

        <section className="row p-0">

            <p className="subtitle mt-5">Information fiancières</p>
            
            <hr />

            <div className="frameInformation col-12">
                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Code postale RCS</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.code_postal_rcs ? informations_financieres.code_postal_rcs : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">Greffe</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.greffe ? informations_financieres.greffe : na}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Forme juridique</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.forme_juridique ? informations_financieres.forme_juridique : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">Ville</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.ville ? informations_financieres.ville : na}
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">CA 2022</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.ca_2022 ? informations_financieres.ca_2022 + euros : na}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">CA 2020</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.ca_2020 ? informations_financieres.ca_2020 + euros : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">CA 2021</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.ca_2021 ? informations_financieres.ca_2021 + euros : na}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">CA 2018</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.ca_2018 ? informations_financieres.ca_2018 + euros : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">CA 2019</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.ca_2019 ? informations_financieres.ca_2019 + euros : na}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">CA 2016</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.ca_2016 ? informations_financieres.ca_2016 + euros : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">CA 2017</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.ca_2017 ? informations_financieres.ca_2017 + euros : na}
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

              

                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Résultat 2022</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.resultat_2022 ? informations_financieres.resultat_2022 + euros : na}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Résultat 2020</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.resultat_2020 ? informations_financieres.resultat_2020 + euros : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">Résultat 2021</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.resultat_2021 ? informations_financieres.resultat_2021 + euros : na}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Résultat 2018</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.resultat_2018 ? informations_financieres.resultat_2018 + euros : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">Résultat 2019</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.resultat_2019 ? informations_financieres.resultat_2019 + euros : na}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Résultat 2016</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.resultat_2016 ? informations_financieres.resultat_2016 + euros : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">Résultat 2017</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.resultat_2017 ? informations_financieres.resultat_2017 + euros : na}
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Effectif 2021</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.effectif_2021 ? informations_financieres.effectif_2021 : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">Effectif 2022</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.effectif_2022 ? informations_financieres.effectif_2022 : na}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Effectif 2019</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.effectif_2019 ? informations_financieres.effectif_2019 : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">Effectif 2020</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.effectif_2020 ? informations_financieres.effectif_2020 : na}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Effectif 2017</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.effectif_2017 ? informations_financieres.effectif_2017 : na}
                            </div>
                        </div>
                    </div>
                    <div className="one-block-information">
                        <div className="information-name">Effectif 2018</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.effectif_2018 ? informations_financieres.effectif_2018 : na}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-informations">
                    <div className="one-block-information">
                        <div className="information-name">Effectif 2016</div>
                        <div className="information-copy">
                            <div>
                                {informations_financieres.effectif_2016 ? informations_financieres.effectif_2016 : na}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}
