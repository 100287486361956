import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

// Packages
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Services
import { getUnitSearchBySiret } from '../../services/Search/search'

// Component
import Loader from '../Loader'
import AllInformations from './AllInformations'



export default function UnitySearch({ token, isSubscribed }) {
    const { siren } = useParams();

    const [response, setResponse] = useState([])
    const [errors, setErrors] = useState(false)
    const [wrongSiret, setWrongSiret] = useState(false)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (Number.isInteger(parseInt(siren))) {
            if (siren.length === 14) {
                // -----  SIRET = 14 ----- 
                getUnitSearchBySiret(siren, setResponse, setErrors, setLoading)
            } else {
                setWrongSiret(true)
            }
        }
    }, [siren])

    return (
        <HelmetProvider>
            <Helmet prioritizeSeoTags>
                <title>Recherche unitaire - AlizVerif</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" value="Votre assistant virtuel de vérification et de nettoyage de données légales d'entreprise" />
                <link rel="canonical" href="https://verif.aliz.io" />
                <meta property="og:title" content="" />
            </Helmet>
            <div className="page">
                <div id="firstPage">

                    <div className="bubble-turquoise" id="bubble1"></div>
                    <div className="bubble-turquoise" id="bubble2"></div>
                    <div className="bubble-turquoise" id="bubble3"></div>
                    <div className="bubble-turquoise" id="bubble4"></div>
                    <div className="bubble-turquoise" id="bubble5"></div>

                    <div id="resultPage">
                        {errors && <p className="no-result">Désolé, aucun résultat n'a été trouvé.</p>}
                        {wrongSiret && <p className="no-result">Désolé, le N° de SIRET n'est pas valide.</p>}

                        {(() => {
                            if (errors === false) {
                                if (typeof response !== 'undefined' && typeof response.informations_generales === "undefined" && loading === false) {
                                    return <Loader />;
                                } else if (typeof response !== 'undefined' && typeof response.informations_generales !== "undefined" && loading === false) {
                                    return <AllInformations response={response} token={token} isSubscribed={isSubscribed} />
                                } else {
                                    return <Loader />;
                                }
                            }
                        })()}
                    </div>

                </div>
            </div>
        </HelmetProvider>

    )
}
