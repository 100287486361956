import axios from 'axios';

const url = process.env.REACT_APP_API_ALIZ_ENDPOINT;

const headers = {
    'Content-Type': 'application/json'
};
const headersAuth = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + localStorage.getItem('tokenAliz'),
}

// __________________ Check Token ___________________
export async function authToken(token, setResponse) {
    await axios({
        url: url + 'auth/user',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + token,
        }
        ,
    })
        .then((response) => {
            // ----- Send response for check token ----- 
            if (response.data) {
                localStorage.setItem('tokenAliz', token);
                setResponse(true)

            } else {
                setResponse(false)
            }
        }, (err) => {
            setResponse(false)
        });
}


// __________________ Check Token ___________________
export async function checkToken(setResponse) {
    await axios({
        url: url + 'auth/user',
        headers: headersAuth,
    })
        .then((response) => {
            // -----  User informations ----- 
            if (response.data) {
                setResponse(response.data)
            } else {
                setResponse(false)
            }
        }, (err) => {
            setResponse(false)
        });
}

export async function newEmailFooter(data, setResponse, setErrors) {
    await axios({
        method: 'POST',
        url: url + 'footer/email',
        headers: headers,
        data: data
    })
        .then((response) => {
            if (response.data.errors) {
                setErrors(response.data.errors)
            } else {
                setResponse("success");
            }
        }, (err) => {
            setResponse(err)
        });
}


