import React from 'react'

// Components
import DownloadFile from './DownloadFile'
import SubscribeToEnjoy from '../SubscribeToEnjoy'
import IsBloquedPeriod from '../IsBloquedPeriod'

export default function HowItWorks({ responseToken, isSubscribed }) {

    return (
        <div id="howitWorks-component">
            <h2>Comment ça marche ?</h2>

             {/* ----- DO NOT DELETE Design for explainer vidéo -----  */}
            {/* <LazyLoad>
                <iframe src={process.env.REACT_APP_ALIZ_ADVERTISING_VIDEO} frameBorder="0" allowFullScreen title="Présentation d'Aliz et de ses fonctionnalitées" ></iframe>
            </LazyLoad> */}
            
            <p className="offset-xl-2 col-xl-8 explains">
                Vous pouvez déposer ici votre fichier au format csv, xls ou xlsx (Excel, Spreadsheets Google...) contenant les informations sur les entreprises.
                Aliz va vérifier la véracité de ces données, noter leur pertinence et proposer de les remplacer si elles sont erronées.
                Téléchargez le modèle à compléter pour mettre en forme vos données. Nous vous enverrons par email le fichier corrigé/noté.
            </p>


            {(() => {
                if (isSubscribed.isSubscribed && isSubscribed.isBloqued) {
                    return <IsBloquedPeriod link={process.env.REACT_APP_PUBLIC_URL_ALIZ + "tarifs?assistant=verifications-donn%C3%A9es-l%C3%A9gales&pack-annuel=true"} />
                } else if (responseToken.email && isSubscribed.isSubscribed) {
                    return <DownloadFile email={responseToken.email} isSubscribed={isSubscribed} />
                } else {
                    return <SubscribeToEnjoy type={"recherche multiple"} link={process.env.REACT_APP_PUBLIC_URL_ALIZ + "tarifs?assistant=verifications-donn%C3%A9es-l%C3%A9gales&pack-annuel=true"} />
                }
            })()}

        </div>
    )
}
