import axios from 'axios';

const url = process.env.REACT_APP_VERIF_API_ENDPOINT;
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

export async function getUnitSearchByName(query, typeSearch, orderBy, setResponse, setErrors, setLoading) {
    await axios({
        method: 'GET',
        url: url + 'search?name=' + encodeURI(query),
        headers: headers,
    })
        .then((response) => {

            console.log(response)
            setLoading(false)

            if (response.data.results.length > 0) {
                if (typeof orderBy !== "undefined") {
                    // -----  Sort results by ----- 
                    switch (orderBy) {
                        case "ASC":
                            setResponse(response.data.results.sort(function (a, b) {
                                return (a.informations_generales.denomination === null) - (b.informations_generales.denomination === null) || +(a.informations_generales.denomination > b.informations_generales.denomination) || -(a.informations_generales.denomination < b.informations_generales.denomination);
                            }));
                            break;
                        case "DESC":
                            setResponse(response.data.results.sort(function (a, b) {
                                return (a.informations_generales.denomination === null) - (b.informations_generales.denomination === null) || -(a.informations_generales.denomination > b.informations_generales.denomination) || +(a.informations_generales.denomination < b.informations_generales.denomination);
                            }));
                            break;
                        case "DATE_CREATION_ASC":
                            setResponse(response.data.results.sort(function (a, b) {
                                return (a.informations_generales.date_creation === null) - (b.informations_generales.date_creation === null) || +(new Date(a.informations_generales.date_creation) > new Date(b.informations_generales.date_creation)) || -(new Date(a.informations_generales.date_creation) < new Date(b.informations_generales.date_creation));
                            }));
                            break;
                        case "DATE_CREATION_DESC":
                            setResponse(response.data.results.sort(function (a, b) {
                                return (a.informations_generales.date_creation === null) - (b.informations_generales.date_creation === null) || -(new Date(a.informations_generales.date_creation) > new Date(b.informations_generales.date_creation)) || +(new Date(a.informations_generales.date_creation) < new Date(b.informations_generales.date_creation));
                            }));
                            break;
                        default:
                            setResponse(response.data.results);
                            break;
                    }
                    setLoading(false)

                } else {
                    setResponse(response.data.results);
                    setLoading(false)

                }
                setLoading(false)

            } else {
                setErrors(true)
                setLoading(false)

            }

            setLoading(false)

        }, (err) => {
            setErrors(true)
            setLoading(false)
        });
}


export async function getUnitSearchBySiren(query, setResponse, setErrors, setLoading) {
    await axios({
        method: 'GET',
        url: url + 'search/siren/' + query,
        headers: headers,
    })
        .then((response) => {
            if (response.status === 200 && response.data.results) {
                setResponse(response.data.results);
                setLoading(false)
            } else {
                setErrors(true)
                setLoading(false)
            }
        }, (err) => {
            setErrors(true)
            setLoading(false)
        });
}


export async function getUnitSearchOneSiren(query, setResponse, setErrors, setLoading) {
    await axios({
        method: 'GET',
        url: url + 'search/siren/' + query,
        headers: headers,
    })
        .then((response) => {
            if (response.status === 200 && response.data.results) {
                if (Array.isArray(response.data.results)) {
                    setResponse(response.data.results[0]);
                } else {
                    setResponse(response.data.results);
                }
                setLoading(false)
            } else {
                setErrors(true)
                setLoading(false)
            }
        }, (err) => {
            setErrors(true)
            setLoading(false)
        });
}


export async function getUnitSearchBySiret(query, setResponse, setErrors, setLoading) {
    await axios({
        method: 'GET',
        url: url + 'search/siret/' + query,
        headers: headers,
    })
        .then((response) => {
            if (response.status === 200 && response.data.results) {
                setResponse(response.data.results);
                setLoading(false)
            } else {
                setErrors(true)
                setLoading(false)
            }
        }, (err) => {
            setErrors(true)
            setLoading(false)
        });
}