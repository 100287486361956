import React from 'react'

// Packages
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Components
import Presentation from './Presentation'
import Search from './Search'
import Summary from '../Summary'
import SubscribeToEnjoy from '../SubscribeToEnjoy'

export default function MultipleSearch({ responseToken, isSubscribed }) {
    // console.log(isSubscribed, responseToken)
    return (
        <HelmetProvider>
            <Helmet prioritizeSeoTags>
                <title>Aliz - Vérification de données légales d'entreprise</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" value="Votre assistant virtuel de vérification et de nettoyage de données légales d'entreprise" />
                <link rel="canonical" href="https://legal-data.aliz.io" />
                <meta property="og:title" content="" />
            </Helmet>

            <div id="multipleSearch-page" className="page">
                <div id="firstPage">
                    <Presentation />
                    <div className="row justify-content-center mt-4">
                        <a className="btn-aliz btn-yellowFull col-10 col-sm-8 col-md-6 col-xl-4" href="/recherche-multiple#uploadFile-component">Rechercher</a>
                    </div>
                </div>

                <Summary />
                {(() => {
                    if (responseToken.email && isSubscribed.isSubscribed) {
                        return <Search email={responseToken.email}/>
                    } else {
                        return <SubscribeToEnjoy type={"recherche multiple"} link={"https://aliz.io/tarifs?assistant=verifications-donn%C3%A9es-l%C3%A9gales&pack-annuel=true"}/>
                    }
                })()}


            </div>
        </HelmetProvider>
    )
}
