import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// Packages
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { scrollTriggerSection } from '../services/Helper/helpFunction'

export default function Prices({ token }) {

    gsap.registerPlugin(ScrollTrigger);

    const sectionOne = document.getElementById('sectionOne');
    const sectionTwo = document.getElementById('sectionTwo');
    const sectionThree = document.getElementById('sectionThree');

    // -----  Animations ----- 
    if (sectionOne) {
        scrollTriggerSection(sectionOne, "x")
    }
    if (sectionTwo) {
        scrollTriggerSection(sectionTwo, "y")
    }
    if (sectionThree) {
        scrollTriggerSection(sectionThree, "x")
    }

    // -----  Get params ----- 
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [annual, setAnnual] = useState(Boolean(query.get("pack-annuel") ? query.get("pack-annuel") : true))

    useEffect(() => {
        if (annual) {
            gsap.set('.annual-pack', { color: "#090E1A" })
            gsap.set('.monthly-pack', { color: "#6F7581" })
        } else {
            gsap.set('.annual-pack', { color: "#6F7581" })
            gsap.set('.monthly-pack', { color: "#090E1A" })
        }
    }, [annual]);

    const [prices, setPrices] = useState(annual ? {
        'starter': process.env.REACT_APP_VERIF_STARTER_ANNUAL_PRICE,
        'business': process.env.REACT_APP_VERIF_BUSINESS_ANNUAL_PRICE,
        'premium': process.env.REACT_APP_VERIF_PREMIUM_ANNUAL_PRICE
    } : {
        'starter': process.env.REACT_APP_VERIF_STARTER_MONTHLY_PRICE,
        'business': process.env.REACT_APP_VERIF_BUSINESS_MONTHLY_PRICE,
        'premium': process.env.REACT_APP_VERIF_PREMIUM_MONTHLY_PRICE
    })
    const [idPrice, setIdPrice] = useState(annual ? {
        'starter': process.env.REACT_APP_PATIENTELE_STARTER_ANNUAL,
        'business': process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL,
        'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_ANNUAL
    } : {
        'starter': process.env.REACT_APP_PATIENTELE_STARTER_MONTHLY,
        'business': process.env.REACT_APP_PATIENTELE_BUSINESS_MONTHLY,
        'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_MONTHLY
    })

    useEffect(() => {
        if (annual) {
            setPrices({
                'starter': process.env.REACT_APP_VERIF_STARTER_ANNUAL_PRICE,
                'business': process.env.REACT_APP_VERIF_BUSINESS_ANNUAL_PRICE,
                'premium': process.env.REACT_APP_VERIF_PREMIUM_ANNUAL_PRICE
            })
            setIdPrice({
                'starter': process.env.REACT_APP_PATIENTELE_STARTER_ANNUAL,
                'business': process.env.REACT_APP_PATIENTELE_BUSINESS_ANNUAL,
                'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_ANNUAL
            })
        } else {
            setPrices({
                'starter': process.env.REACT_APP_VERIF_STARTER_MONTHLY_PRICE,
                'business': process.env.REACT_APP_VERIF_BUSINESS_MONTHLY_PRICE,
                'premium': process.env.REACT_APP_VERIF_PREMIUM_MONTHLY_PRICE
            })
            setIdPrice({
                'starter': process.env.REACT_APP_PATIENTELE_STARTER_MONTHLY,
                'business': process.env.REACT_APP_PATIENTELE_BUSINESS_MONTHLY,
                'premium': process.env.REACT_APP_PATIENTELE_PREMIUM_MONTHLY
            })
        }
    }, [annual]);

    const feature = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg>;
    // ----- Feature not included in subscription ----- 
    const featureInactive = <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg></td>;

    // -----  https://aliz.io OR https://staging.aliz.io ----- 
    const alizUrl = process.env.REACT_APP_PUBLIC_URL_ALIZ


    return (
        <div id="prices-component">

            <h2>Nos tarifs</h2>


            {/* ----- Monthly or Annual subscription ----- */}
            <div className="switch-annual">
                <p className="monthly-pack">PLAN MENSUEL</p>

                <label className="switch">
                    <input type="checkbox" id="pack" name="pack" checked={annual} onChange={(e) => setAnnual(e.target.checked)} />
                    <span className="slider round"></span>
                </label>

                <p className="annual-pack">PLAN ANNUEL <sup>-30%</sup></p>
            </div>


            <div id="componentGroup">
                {/* ----- Pack STARTER -----  */}
                <section className="onePrice" id="sectionOne">

                    <p className="pack-title">GRATUIT</p>

                    <h3>Starter</h3>

                    <p className="pack-description">
                        Pour accéder à toutes les données d’une recherche de société, faire des recherches de société en masse et fiabiliser vos bases de données.
                    </p>

                    <hr></hr>

                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="features-block">
                        <div className="features-description">
                            {feature}
                            <p>Données sur l’entreprise et sur le dirigeant</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Analyse de la qualité des données avec le <b>ALIZ TRUST SCORING</b></p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Accès aux recherches multiples</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>25 crédits/mois pour tester la vérification et le nettoyage de base de données</p>
                        </div>
                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Alerte et mise à jour de votre base de données</p>
                        </div>
                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Accès à l’API AlizVerif pour intégration dans vos systèmes d’informations, ERP, CRM...</p>
                        </div>
                    </div>
                    <button className="btn-aliz btn-yellowFull"
                        onClick={() =>
                            token ?
                                window.location.href = alizUrl + "espace-client/souscription-etape1?assistant=4&price=" + idPrice.starter :
                                window.location.href = alizUrl + 'login?assistant=4&price=' + idPrice.starter
                        }
                    >Tester Gratuitement</button>
                </section>
                {/* ----- End Pack STARTER 1 -----  */}



                {/* ----- Pack BUSINESS -----  */}
                <section className="onePrice" id="sectionTwo">

                    <p className="pack-title">{prices.business}€/mois</p>

                    <h3>Business</h3>

                    <p className="pack-description">
                        Tous les avantages du Starter + toutes les fonctionnalités du nettoyage de bases de données pour améliorer la qualité de vos bases fournisseurs et clients.
                    </p>

                    <hr></hr>

                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="features-block">
                        <div className="features-description">
                            {feature}
                            <p>Données sur l’entreprise et sur le dirigeant</p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Analyse de la qualité des données avec le <b>ALIZ TRUST SCORING</b></p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>Accès aux recherches multiples</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>500 crédits/mois pour vérifier et nettoyer vos bases de données</p>
                        </div>
                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Alerte et mise à jour de votre base de données</p>
                        </div>
                        <div className="features-description features-inactive">
                            {featureInactive}
                            <p>Accès à l’API AlizVerif pour intégration dans vos systèmes d’informations, ERP, CRM...</p>
                        </div>
                    </div>

                    <button className="btn-aliz btn-yellowFull"
                        onClick={() =>
                            token ?
                                window.location.href = alizUrl + "espace-client/souscription-etape1?assistant=4&price=" + idPrice.business :
                                window.location.href = alizUrl + 'login?assistant=4&price=' + idPrice.business
                        }
                    >
                        Tester Gratuitement
                    </button>

                </section>
                {/* -----  End Pack BUSINESS -----  */}




                {/* ----- Pack PREMIUM (ENTERPRISE) ----- */}
                <section className="onePrice" id="sectionThree">

                    <p className="pack-title">Sur devis</p>

                    <h3>Enterprise</h3>

                    <p className="pack-description">
                        Tous les avantages du Business + intégrer AlizVerif à votre outil, recevoir des alertes en cas de mise à jour des données et nettoyer en masse vos base de données.
                    </p>

                    <hr></hr>

                    <p className="pack-features">Fonctionnalités principales</p>

                    <div className="features-block">
                        <div className="features-description">
                            {feature}
                            <p>Données sur l’entreprise et sur le dirigeant</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>Analyse de la qualité des données avec le <b>ALIZ TRUST SCORING</b></p>
                        </div>

                        <div className="features-description">
                            {feature}
                            <p>Accès aux recherches multiples</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>Crédits illimités pour nettoyer et améliorer la qualité de vos base de données</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>Alerte et mise à jour de votre base de données</p>
                        </div>
                        <div className="features-description">
                            {feature}
                            <p>Accès à l’API AlizVerif pour intégration dans vos systèmes d’informations, ERP, CRM...</p>
                        </div>
                    </div>

                    <button className="btn-aliz btn-yellowFull"
                        onClick={() => window.open(process.env.REACT_APP_LINK_CALENDAR_VERIF_ENTERPRISE_PACK, '_blank')}
                    >
                        Tester Gratuitement
                    </button>

                </section>
                {/* ----- End Pack PREMIUM (ENTERPRISE) -----  */}

            </div>
        </div>
    )
}
