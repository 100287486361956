import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Packages
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Components
import Presentation from './Presentation'
import GoogleMyBusiness from '../GoogleMyBusiness'
import Prices from '../Prices'
import OurServices from '../OurServices'
import UnitSearch from './UnitSearch'

// Services
import { authToken } from '../../services/auth'
import { searchType } from '../../services/Constant/typeSearch'
import { getUnitSearchByName } from '../../services/Search/search'


export default function Home({ token }) {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    // ----- Check user token -----
    const [responseToken, setResponseToken] = useState(false)
    const [newrequest, setNewrequest] = useState(false)
    useEffect(() => {
        const connection = async () => {
            if (query.get("token")) {
                await authToken(query.get("token"), setResponseToken)
            }
        }
        connection()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (responseToken && query.get("token")) {
            window.location.href = "/"
        }
        // eslint-disable-next-line
    }, [responseToken])


    // _________ Input search _________
    const [searchEnterprise, setSearchEnterprise] = useState(query.get("search") ? query.get("search") : "")

    let navigate = useNavigate();


    // _________ Select _____
    const [orderBy, setOrderBy] = useState(false)

    // On écoute les changements sur le type de request ainsi que le orderBy, mais si aucun ne change -> new request
    const pushSearch = () => {
        setErrors(false)
        setLoading(true)
        setResponse([])
        setNewrequest(true)

        document.getElementById('searchResult-component').scrollIntoView();

        if (Number.isInteger(parseInt(searchEnterprise))) {
            if (searchEnterprise.length === 9) {
                // SIREN = 9
                setTypeSearch(searchType.SIREN)
                setSirenNumber(searchEnterprise)
            } else if (searchEnterprise.length === 14) {
                setTypeSearch(searchType.SIRET)
            }
        } else {
            setTypeSearch(searchType.NAME)

        }
    }


    const [typeSearch, setTypeSearch] = useState(false)
    const [response, setResponse] = useState([])
    const [errors, setErrors] = useState(false)
    const [loading, setLoading] = useState(false)
    const [sirenNumber, setSirenNumber] = useState("")


    useEffect(() => {
        if (searchEnterprise.length > 0) {
            navigate('?search=' + searchEnterprise.replace(/ /g, "-"));
        }
        // eslint-disable-next-line
    }, [searchEnterprise])

    useEffect(() => {
        if (typeSearch) {
            getUnitSearchByName(searchEnterprise, typeSearch, orderBy, setResponse, setErrors, setLoading)
        }
        setNewrequest(false)
        // eslint-disable-next-line
    }, [typeSearch, orderBy, newrequest])


    return (
        <HelmetProvider>
            <Helmet prioritizeSeoTags>
                <title>Aliz - Vérification de données légales d'entreprise</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" value="Votre assistant virtuel de vérification et de nettoyage de données légales d'entreprise" />
                <link rel="canonical" href="https://legal-data.aliz.io" />
                <meta property="og:title" content="" />
            </Helmet>

            <div id="home" className="page">
                <div id="firstPage">
                    <Presentation
                        pushSearch={pushSearch}
                        searchEnterprise={searchEnterprise}
                        setSearchEnterprise={setSearchEnterprise} />
                    <GoogleMyBusiness />
                </div>
                <OurServices />
                <UnitSearch
                    token={token}
                    pushSearch={pushSearch}
                    searchEnterprise={searchEnterprise}
                    setSearchEnterprise={setSearchEnterprise}
                    response={response}
                    errors={errors}
                    loading={loading}
                    setOrderBy={setOrderBy}
                    orderBy={orderBy}
                    sirenNumber={sirenNumber}
                />
                <Prices />
            </div>
        </HelmetProvider>
    )
}
