import React, { useState, useEffect, useRef } from 'react'

// Packages
import Select from 'react-select'
import gsap from 'gsap'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// Component
import DoSearchNow from '../DoSearch/DoSearchNow'
import PopupDownload from './../PopupDownload'
import ResultByInputs from './ResultByInputs'


// Services Axios
import { getMultipleSearchByInputs } from '../../services/Search/multipleSearch'

export default function SearchByInputs({ email }) {

    const [visibleText, setVisibleText] = useState(false)
    const [response, setResponse] = useState([])
    const [errors, setErrors] = useState(false)
    const [loading, setLoading] = useState(false)

    const addInputRef = useRef()

    // ----- Select value -----
    const [inputChoice, setInputChoice] = useState("siret")

    // ----- Select choice Options -----
    const optionsInputChoice = [
        { value: "siret", label: 'N° Siret (14 chiffres)' },
        { value: "siren", label: 'N° Siren (9 chiffres)' }
    ];

    const onSearchEnterpriseByInputs = () => {
        let siretNumbers = "";
        let sirenNumbers = "";

        setErrors([])

        if (inputsComponent.length > 0) {

            inputsComponent.forEach(input => {
                if (input.type === "siret" && input.value.length !== 14) {
                    setErrors("Veuillez saisir un N° de SIRET correct (14 chiffres).")
                } else if (input.type === "siren" && input.value.length !== 9) {
                    setErrors("Veuillez saisir un N° de SIREN correct (9 chiffres).")
                } else if (isNaN(input.value)) {
                    setErrors("Veuillez saisir uniquement des chiffres.")
                }
                if (input.type === "siret") {
                    siretNumbers += siretNumbers === "" ? input.value : "," + input.value;
                } else if (input.type === "siren") {
                    sirenNumbers += sirenNumbers === "" ? input.value : "," + input.value;
                }
            });
            setLoading(true)

            // ----- Conditions to choose the parameters to add in order to avoid an empty parameter call -----
            const URI_PARAMS = sirenNumbers && siretNumbers ? 'search/list?siren=' + sirenNumbers + '&siret=' + siretNumbers : sirenNumbers ? 'search/list?siren=' + sirenNumbers : siretNumbers ? 'search/list?siret=' + siretNumbers : 'search/list?siren=' + sirenNumbers + '&siret=' + siretNumbers;
            getMultipleSearchByInputs(setResponse, setErrors, setLoading, URI_PARAMS)
        } else {
            setResponse(false)
            setErrors("Veuillez ajouter une recherche.")
        }
    }

    useEffect(() => {
        if (response.status === 200 && !loading) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <PopupDownload onClose={onClose} email={email} />
                    );
                }
            });
        }
        // eslint-disable-next-line
    }, [response])

    // ----- On btn hover, display: "Ajouter" -----
    const addInput = () => {
        if (visibleText) {
            gsap.to('.add-input-circle p', { duration: 0, display: 'none' })
            setVisibleText(false)
        } else {
            gsap.to('.add-input-circle p', { duration: 0, display: 'block' })
            setVisibleText(true)
        }
    }
    // ----- On btn hover, display: "Supprimer" -----
    const removeInput = (index) => {
        const textRemove = document.getElementById("remove_" + index)
        if (textRemove.style.display === "block") {
            gsap.to("#remove_" + index, { duration: 0, display: 'none' })
        } else {
            gsap.to("#remove_" + index, { duration: 0, display: 'block' })
        }
    }

    // ----- Add, remove & listen inputs -----
    const [inputsComponent, setInputsComponent] = useState([])


    const addInputField = (type) => {
        let placeholder = ""
        optionsInputChoice.forEach(element => {
            if (element.value === type) {
                placeholder = element.label
            }
        });
        setInputsComponent([...inputsComponent, {
            'value': '',
            'type': type,
            'placeholder': placeholder
        }])
    }

    const removeInputFields = (index) => {
        const rows = [...inputsComponent];
        rows.splice(index, 1);
        setInputsComponent(rows);
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputsComponent];
        list[index][name] = value;
        setInputsComponent(list);
    }

    return (
        <div>
            <div className="row justify-content-center">
                <div className="hrSeparator col-lg-10 col-xl-8">Ou</div>
            </div>
            <div className="row justify-content-center">
                <div id="search-frame" className="col-lg-10 col-xl-8 search-frame">


                    <DoSearchNow />


                    <div className="d-flex max-sm-flex-direction-column">
                        <Select
                            defaultValue={inputChoice}
                            onChange={(e) => setInputChoice(e.value)}
                            options={optionsInputChoice}
                            placeholder={"Ajouter un champ de recherche"}
                            className="custom-select col-xl-6"
                        />
                        <div className="input-circle add-input-circle"
                            ref={addInputRef}
                            onMouseEnter={addInput}
                            onMouseLeave={addInput}
                            onClick={() => addInputField(inputChoice)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg>
                            <p>Ajouter</p>
                        </div>
                    </div>


                    {inputsComponent.map((element, index) => (
                        <div className="d-flex max-lg-flex-direction-column" key={index}>
                            <div className="col-xl-9">
                                <div className="input-block">
                                    <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={element.value} name="value" placeholder={element.placeholder} maxLength={element.type === "siret" ? 14 : 9} minLength={element.type === "siret" ? 14 : 9} required={true} />
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            {
                                index >= 0 ?
                                    <div className="input-circle remove-input-circle"
                                        onClick={() => removeInputFields(index)}
                                        onMouseEnter={() => removeInput(index)}
                                        onMouseLeave={() => removeInput(index)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                        <p id={"remove_" + index}>Supprimer</p>

                                    </div>
                                    : null
                            }
                        </div>
                    ))}

                    {errors && <span className="text-danger d-block mt-3">{errors}</span>}

                    <button onClick={onSearchEnterpriseByInputs} className="btn-aliz btn-yellowFull">Envoyer</button>
                </div>
            </div>

            <ResultByInputs errors={errors} response={response} loading={loading} />
        </div >
    )
}
