import React, { useEffect, useState } from 'react'


export default function Bodacc({ response }) {

    const [dateCommencementActivite, setDateCommencementActivite] = useState("")
    const [dateCloture, setDateCloture] = useState("")
    const [title, setTitle] = useState([])

    const na = "N/A";

    useEffect(() => {
        // En fonction du type d'annonce, les informations principales varient
        switch (response.fields.publicationavis) {
            case "A":
                const acte = JSON.parse(response?.fields?.acte)
                setTitle(acte?.creation?.categorieCreation)
                setDateCommencementActivite(acte?.creation?.dateCommencementActivite)
                break;
            case "B":
                const modificationsgenerales = JSON.parse(response?.fields?.modificationsgenerales)
                setTitle(modificationsgenerales?.descriptif)
                break;
            case "C":
                const depot = JSON.parse(response?.fields?.depot)
                setDateCloture(depot?.dateCloture)
                setTitle(depot?.typeDepot)

                break;
            default:
                break;
        }
        // const date = new Date(response.date_parutation)
        // const day = date.getDay().toString().length < 2 ? "" + 0 + date.getDay() : date.getDay()
        // const month = date.getMonth().toString().length < 2 ? "" + 0 + date.getMonth() : date.getMonth()
        // setDatePublication(day + "-" + month + "-" + date.getFullYear())
    }, [])


    return (
        <div className="frameInformation col-12">

            <h3 className="my-4 text-center"> {title}</h3>

            {response.fields.typeavis_lib && <p>{response.fields.typeavis_lib}</p>}

            <div className="block-informations">
                <div className="one-block-information">
                    <div className="information-name">Publié le</div>
                    <div className="information-copy">
                        <div>
                            {response.fields.dateparution ? response.fields.dateparution : na}
                        </div>
                    </div>
                </div>
                <div className="one-block-information">
                    <div className="information-name">Tribunal</div>
                    <div className="information-copy">
                        <div>
                            {response.fields.tribunal ? response.fields.tribunal : na}
                        </div>
                    </div>
                </div>
            </div>



            <div className="block-informations">
                <div className="one-block-information">
                    <div className="information-name">Type</div>
                    <div className="information-copy">
                        <div>
                            {response.fields.familleavis_lib ? response.fields.familleavis_lib : na}
                        </div>
                    </div>
                </div>

                <div className="one-block-information">
                    <div className="information-name">Ville</div>
                    <div className="information-copy">
                        <div>
                            {response.fields.ville && response.fields.region_nom_officiel ? response.fields.ville + ", " + response.fields.region_nom_officiel : na}
                        </div>
                    </div>
                </div>
            </div>

            <div className="block-informations">

                <div className="one-block-information">
                    <div className="information-name">Facette</div>
                    <div className="information-copy">
                        <div>
                            {response.fields.publicationavis_facette ? response.fields.publicationavis_facette : na}
                        </div>
                    </div>
                </div>

                {(() => {
                    if (dateCommencementActivite) {
                        return <div className="one-block-information">
                            <div className="information-name">Commencement de l'activité le</div>
                            <div className="information-copy">
                                <div>
                                    {dateCommencementActivite}
                                </div>
                            </div>
                        </div>
                    } else if (dateCloture) {
                        return <div className="one-block-information">
                            <div className="information-name">Date de cloture</div>
                            <div className="information-copy">
                                <div>
                                    {dateCloture}
                                </div>
                            </div>
                        </div>
                    }
                })()}
            </div>


            <div className="block-informations">
                <div className="one-block-information">
                    <div className="information-name">Registre</div>
                    <div className="information-copy">
                        <div>
                            {response.fields.registre ? response.fields.registre : na}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-2">
                <a className="text-center " href={"https://www.bodacc.fr/pages/annonces-commerciales-detail/?q.id=id:" + response.fields.id} target="_blank" rel="noopener noreferrer">Consulter l'annonce</a>
            </div>
        </div>
    )
}
